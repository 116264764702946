import React, { useContext, useState } from "react";
import DownArrowSquare from "../Icons/DownArrowSquare";
import Trash from "../Icons/Trash";
import UpArrowSquare from "../Icons/UpArrowSquare";
import ConfirmDialog from "./ConfirmDialog"; // Import the new component

import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";
import Stars from "./Stars";
import CircleCheck from "../Icons/CircleCheck";

// Update your BookCardWrapper styled component
const BookCardWrapper = styled.div`
  /* Your existing styles */
  transition: all 0.3s ease-in-out;
  opacity: ${props => props.isDeleting ? 0 : 1};
  transform: scale(${props => props.isDeleting ? 0.95 : 1});
`;


// First, modify your action button styled component for better feedback
const ActionButton = styled.div`
  width: 50px;
  height: 50px;
  background-color: rgba(255, 250, 250, 0.8);
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Make sure cursor changes to indicate clickable */
  z-index: 5; /* Ensure buttons are on top */
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }

  &:hover {
    background-color: ${(props) =>
      props.delete ? "rgba(255, 87, 69, 0.8)" : "rgba(6, 69, 92, 0.8)"};
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.7; /* Visual feedback when clicked */
  }
`;

const HiddenActionButton = styled.div`
  width: 50px;
  height: 50px;

  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

const ReadButton = styled.div`
  width: 150px;
  height: 50px;
  background-color: rgba(26, 89, 112, 0.1); /* Brand color background with low opacity */
  margin: 0px 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  color: #1a5970; /* Brand color text */
  font-weight: 500;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    width: 120px;
    height: 40px;
    font-size: 14px;
  }
  
  &:hover {
    background-color: rgba(26, 89, 112, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }
  
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Card = styled.div`
  border: 0;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 600px;
  margin: auto;
  position: relative;
  display: flex;
  align-self: center;
  width: 100%;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  }

  h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    line-height: 1.2;
    
    @media (max-width: 768px) {
      font-size: 18px;
    }
    
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  
  p {
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
  
  small {
    font-size: 16px;
    color: #667c8a;
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
    
    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

// Update the CardRow component to use column layout in the problematic range
const CardRow = styled.div`
  margin-right: 0;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
  
  @media (max-width: 1550px) and (min-width: 1302px) {
    /* Switch to column layout for the problematic range */
    flex-direction: column;
  }
  
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

// Update the CardImage component with better sizing for middle range
const CardImage = styled.div`
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  
  @media (max-width: 1550px) and (min-width: 1302px) {
    /* Full width but moderate height for the image */
    flex: 0 0 100%;
    max-width: 100%;
    height: 150px; /* Shorter than mobile but still prominent */
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
  
  @media (max-width: 768px) {
    flex: 0 0 40%;
    max-width: 40%;
  }
  
  @media (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  img {
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    width: 100%;
    vertical-align: middle;
    border-style: none;
    
    @media (max-width: 480px) {
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
`;

// Update the CardInfo component to take full width
const CardInfo = styled.div`
  padding-right: 0;
  padding-left: 0;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  width: 100%;
  
  @media (max-width: 1550px) and (min-width: 1302px) {
    /* Full width for text content */
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  @media (max-width: 768px) {
    flex: 0 0 60%;
    max-width: 60%;
  }
  
  @media (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

// Simplify BookInfo for the middle range
const BookInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr;
  gap: 10px;
  height: 100%;
  padding: 25px;
  
  @media (max-width: 1550px) and (min-width: 1302px) {
    /* More compact layout for this range */
    padding: 15px;
    gap: 5px;
    grid-template-rows: auto auto;
    
    /* Arrange ratings next to pages for more efficient space use */
    .book-metadata {
      display: flex;
      flex-direction: column;
    }
    
    .bottom-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;
    }
    
    .book-pages {
      margin-top: 0 !important;
    }
    
    .book-ratings {
      flex-direction: row !important;
      align-items: center;
      
      .ratings-count {
        margin-top: 0 !important;
        margin-left: 10px;
      }
    }
  }
  
  @media (max-width: 768px) {
    padding: 15px;
    gap: 5px;
  }
  
  @media (max-width: 480px) {
    padding: 15px;
    grid-template-rows: auto auto;
  }
  
  .book-metadata {
    margin-bottom: 10px;
    overflow: hidden; /* Prevent overflow */
  }
  
  .book-title {
    color: #1a5970;
    margin-bottom: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  
  .book-series {
    font-style: italic;
    color: #667c8a;
  }
  
  .book-pages {
    font-weight: 600;
    font-size: 18px;
    color: #1a5970;
    margin-top: 10px;
    
    @media (max-width: 768px) {
      font-size: 16px;
      margin-top: 5px;
    }
  }
  
  .book-ratings {
    display: flex;
    flex-direction: column;
    
    .ratings-count {
      margin-top: 4px;
    }
  }
`;
export const BookCard = React.memo((props) => {
  const {
    user,
    bookList,
    markBookAsComplete,

    updateUserData,
    sendBookToTop,
    sendBookToBottom,
    dragable,

    deleteBookFromUser,
  } = useContext(LocalStateContext);
  const {
    _id: id,
    title,
    image,
    author,
    series,
    releaseYear,
    pages,
    averageRating,
    ratingsCount,
  } = props.book?.book;

  const { index } = props;
  const [isDeleting, setIsDeleting] = useState(false);
    // Add state for the confirm dialog
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

const markCompleteFlow = async () => {
  const userId = typeof user === 'object' ? user.id : user;
  
  await markBookAsComplete({ id, user: userId });
  await deleteBookFromUser({ bookId: id, userId: userId });
  updateUserData();
};


// Also update the other handler functions that use the user object:

const handleSendToTop = (e) => {
  e.preventDefault();
  e.stopPropagation();
  console.log('Sending book to top:', id);
  
  const userId = typeof user === 'object' ? user.id : user;
  sendBookToTop({ bookId: id, userId: userId });
};

const handleSendToBottom = (e) => {
  e.preventDefault();
  e.stopPropagation();
  console.log('Sending book to bottom:', id);
  
  const userId = typeof user === 'object' ? user.id : user;
  sendBookToBottom({ bookId: id, userId: userId });
};

  // Update the delete handler to show dialog instead of using window.confirm
  const handleDeleteBook = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Preparing to delete book:', id);
    
    // Show our custom confirm dialog
    setShowConfirmDialog(true);
  };
  
  // New function to handle the actual deletion after confirmation
  const confirmDelete = async () => {
    try {
      // Start the fade-out animation
      setIsDeleting(true);
      
      // Wait a bit for the animation to start
      await new Promise(resolve => setTimeout(resolve, 100));
      
      // Extract just the user ID if user is an object
      const userId = typeof user === 'object' ? user.id : user;
      
      // Delete the book
      await deleteBookFromUser({ bookId: id, userId: userId });
      
      // Close dialog
      setShowConfirmDialog(false);
    } catch (error) {
      console.error("Error deleting book:", error);
      // If there's an error, cancel the animation
      setIsDeleting(false);
      setShowConfirmDialog(false);
    }
  };



const handleMarkAsRead = (e) => {
  e.preventDefault();
  e.stopPropagation();
  console.log('Marking book as read:', id);
  markCompleteFlow();
};

// In your BookCard component's return statement:
return (
  <>
        <ConfirmDialog
        isOpen={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={confirmDelete}
        title="Remove Book"
        message="Are you sure you want to remove"
        itemName={title}
        confirmText="Remove"
        cancelText="Keep"
      />
  
  <BookCardWrapper isDeleting={isDeleting}>
  <div>
    <Card>
      <CardRow>
        <CardImage>
          <img src={image} alt={title} />
        </CardImage>
        <CardInfo>
          <BookInfo>
            <div className="book-metadata">
              <h4 className="book-title">
                {title} <small>by {author}</small>
                {releaseYear && <small className="text-muted"> ({releaseYear})</small>}
              </h4>

              {series && <p className="book-series">{series}</p>}
             {/* Add a bottom-row div to enable the side-by-side layout in medium screens */}
              <div className="bottom-row">
                <p className="book-pages">{pages} pages</p>
                <div className="book-ratings">
                  <Stars stars={averageRating} />
                  <p className="ratings-count">
                    <small>from {ratingsCount} reviews</small>
                  </p>
                </div>
                   </div>
            </div>
          </BookInfo>
        </CardInfo>
      </CardRow>
      
      {/* Action button overlay */}
{dragable && (
  <div
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    }}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <ActionButton
          onClick={handleSendToTop}
        style={{ pointerEvents: "auto" }}
      >
        <UpArrowSquare size="25px" />
      </ActionButton>
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <ActionButton
             onClick={handleSendToBottom}
        style={{ pointerEvents: "auto" }}
      >
        <DownArrowSquare size="25px" />
      </ActionButton>
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <ActionButton
      delete
        onClick={handleDeleteBook}
        style={{ pointerEvents: "auto" }}
      >
        <Trash size="25px" />
      </ActionButton>
    </div>
  </div>
)}
      
      {/* Read button */}
      <div
        style={{
          width: "36%",
          height: "100%",
          position: "absolute",
          bottom: "0",
          right: "0",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignContent: "stretch",
          alignItems: "flex-end",
        }}
      >
        {dragable && index === 0 && (
          <ReadButton       onClick={handleMarkAsRead}
      style={{ pointerEvents: "auto" }}>
            Mark as Read
            <CircleCheck size="25px" fill="#1a5970" /> {/* Updated to brand color */}
          </ReadButton>
        )}
      </div>
    </Card>
  </div>
  </BookCardWrapper>
  </>
);
})
