import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 1000;
  cursor: pointer;
  opacity: ${props => props.visible ? 1 : 0};
  visibility: ${props => props.visible ? 'visible' : 'hidden'};
  transition: opacity 0.3s, visibility 0.3s;
`;

const ScrollTopButton = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 700) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    };
    
    // Add event listener
    window.addEventListener("scroll", toggleVisible);
    
    // Initial check
    toggleVisible();
    
    // Clean up event listener on component unmount
    return () => window.removeEventListener("scroll", toggleVisible);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <ButtonContainer visible={visible} onClick={scrollToTop}>
      <svg
        width="92"
        height="92"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#ffc107"
          fill="#1a5970"
          d="M19 22H5C3.34 22 2 20.66 2 19V5C2 3.34 3.34 2 5 2H19C20.66 2 22 3.34 22 5V19C22 20.66 20.66 22 19 22Z"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#ffc107"
          d="M12 6V18"
        ></path>
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1"
          stroke="#ffc107"
          d="M6 12L12 6L18 12"
        ></path>
      </svg>
    </ButtonContainer>
  );
};

export default ScrollTopButton;