import React, { useState, useContext } from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import IllustrationBookshelf from "../Illustrations/Bookshelf";
import { LocalStateContext } from "../State/LocalState";
import { FiMail, FiLock, FiUser, FiCheck, FiAlertCircle, FiArrowRight } from 'react-icons/fi';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(24, 103, 192, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(24, 103, 192, 0); }
  100% { box-shadow: 0 0 0 0 rgba(24, 103, 192, 0); }
`;

// Container for the entire page
const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%);
  padding: 1.5rem;
`;

// Main card container
const RegisterCard = styled.div`
  display: flex;
  max-width: 1000px;
  width: 100%;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  animation: ${fadeIn} 0.6s ease-out;
  
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 450px;
  }
`;

// Left side with illustration
const BrandSection = styled.div`
  flex: 1;
  background: linear-gradient(135deg, #5e35b1 0%, #1867c0 100%); /* Reversed gradient for distinction */
  color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 2rem;
    align-items: center;
    text-align: center;
  }
`;

const IllustrationContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  svg {
    width: 90%;
    height: auto;
    max-width: 300px;
    filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1));
    path, circle {
      fill: rgba(255, 255, 255, 0.9);
    }
  }
`;

const BrandTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BrandTagline = styled.p`
  font-size: 1.1rem;
  opacity: 0.9;
  line-height: 1.6;
`;

// Right side with form
const FormSection = styled.div`
  flex: 1;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FormTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
`;

const FormSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

const InputIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border: 1px solid ${props => props.error ? '#dc3545' : '#e1e1e1'};
  border-radius: 8px;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: all 0.2s;
  
  &:focus {
    outline: none;
    border-color: #1867c0;
    box-shadow: 0 0 0 3px rgba(24, 103, 192, 0.2);
    background-color: #fff;
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const RegisterButton = styled.button`
  background: linear-gradient(135deg, #5e35b1 0%, #1867c0 100%);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(94, 53, 177, 0.3);
  }
  
  &:active:not(:disabled) {
    transform: translateY(0);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:focus:not(:disabled) {
    animation: ${pulse} 1.5s infinite;
  }
`;

const SignInText = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #666;
  
  a {
    color: #1867c0;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PasswordStrength = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: ${props => {
    if (props.strength === 'strong') return '#28a745';
    if (props.strength === 'medium') return '#ffc107';
    if (props.strength === 'weak') return '#dc3545';
    return '#6c757d';
  }};
`;

const PasswordRequirements = styled.div`
  font-size: 0.75rem;
  color: #6c757d;
  margin-top: 0.25rem;
`;

export const Register = () => {
  let location = useLocation();
  const { setUser } = useContext(LocalStateContext);
  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Password strength function
  const checkPasswordStrength = (password) => {
    if (!password) return '';
    
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const isLongEnough = password.length >= 8;
    
    const strengthScore = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar, isLongEnough].filter(Boolean).length;
    
    if (strengthScore <= 2) return 'weak';
    if (strengthScore <= 4) return 'medium';
    return 'strong';
  };

  const passwordStrength = checkPasswordStrength(password);
  
  // Form validation
  const validateForm = () => {
    if (!name || !email || !password || !confirmPass) {
      setError("All fields are required");
      return false;
    }
    
    if (password !== confirmPass) {
      setError("Passwords do not match");
      return false;
    }
    
    if (passwordStrength === 'weak') {
      setError("Password is too weak");
      return false;
    }
    
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Email address is invalid");
      return false;
    }
    
    return true;
  };

  const registerUser = async ({ email, password, name }) => {
    if (!validateForm()) return;
    
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${BACKEND_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload: {
            name,
            email,
            password,
          },
        }),
      });
      
      const data = await response.json();
      
      if (response.ok && data.payload) {
        // Store user data
        localStorage.setItem("user", JSON.stringify(data.payload));
        setUser(data.payload);
        setRedirect(true);
      } else {
        setError(data.message || "Registration failed. Please try again.");
      }
    } catch (err) {
      setError("Registration failed. Please try again later.");
      console.error("Registration error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      {redirect && <Navigate to="/main" state={{ from: location }} replace />}
      
      <Routes>
        <Route
          path="/auth"
          element={
            redirect && <Navigate to="/main" state={{ from: location }} replace />
          }
        />
      </Routes>

      <RegisterCard>
        <BrandSection>
          <BrandTitle>TheNovelist</BrandTitle>
          <BrandTagline>Join our community of book lovers and track your reading journey.</BrandTagline>
          
          <IllustrationContainer>
            <IllustrationBookshelf />
          </IllustrationContainer>
        </BrandSection>
        
        <FormSection>
          <FormTitle>Create Account</FormTitle>
          <FormSubtitle>Start your reading journey today</FormSubtitle>
          
          {error && (
            <ErrorMessage>
              <FiAlertCircle size={16} /> {error}
            </ErrorMessage>
          )}
          
          <FormGroup>
            <InputIcon>
              <FiUser size={18} />
            </InputIcon>
            <Input
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isLoading}
              error={error && !name}
            />
          </FormGroup>
          
          <FormGroup>
            <InputIcon>
              <FiMail size={18} />
            </InputIcon>
            <Input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              error={error && !email}
            />
          </FormGroup>
          
          <FormGroup>
            <InputIcon>
              <FiLock size={18} />
            </InputIcon>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              error={error && (!password || password !== confirmPass)}
            />
            {password && (
              <>
                <PasswordStrength strength={passwordStrength}>
                  {passwordStrength === 'strong' && <FiCheck size={14} style={{marginRight: '4px'}} />}
                  Password strength: {passwordStrength}
                </PasswordStrength>
                <PasswordRequirements>
                  Use 8+ characters with letters, numbers & symbols
                </PasswordRequirements>
              </>
            )}
          </FormGroup>
          
          <FormGroup>
            <InputIcon>
              <FiLock size={18} />
            </InputIcon>
            <Input
              type="password"
              placeholder="Confirm Password"
              value={confirmPass}
              onChange={(e) => setConfirmPass(e.target.value)}
              disabled={isLoading}
              error={error && (!confirmPass || password !== confirmPass)}
            />
          </FormGroup>
          
          <FormActions>
            <RegisterButton 
              onClick={() => registerUser({ name, email, password })}
              disabled={isLoading}
            >
              {isLoading ? "Creating Account..." : "Create Account"} 
              {!isLoading && <FiArrowRight size={18} />}
            </RegisterButton>
          </FormActions>
          
          <SignInText>
            Already have an account? <Link to="/login">Sign in</Link>
          </SignInText>
        </FormSection>
      </RegisterCard>
    </PageContainer>
  );
};