// TODO: Fix the Modals, currently are always visible
import React, { useState, useContext } from "react";
import { styled } from "styled-components";
import { LocalStateContext } from "../State/LocalState";
import { syncGoodReads } from "../Mutations/SyncGoodReads";
import { scrapeBookGoodReads } from "../Mutations/ScrapeBookGoodreads";
import { addBook } from "../Mutations/AddBook";
import ScrollTopButton from "./ScrollTopButton";
import { FiPlus, FiList, FiStar, FiFilter, FiRefreshCw, FiSearch } from "react-icons/fi";

// React Modal Imports
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ModalAddBookForm from "./ModalAddBookForm";



const ColumnContainer = styled.div`
  padding: 1.5rem;
  margin-top: 3rem;
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6;
  border-top: 6px solid #1a5970;
  border-radius: 0 0 4px 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  h3 {
    font-weight: 700;
    margin: 1.25rem 0 0.75rem;
    font-size: 1.25rem;
    color: #1a5970;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.5rem;
  }
`;

  const ControlItem = styled.div`
    margin-bottom: 0.75rem; /* 12px */
  `;

  const AddModal = styled(Modal)`
    background-color: red;
    padding: 1.5rem !important;
    margin-top: 3rem !important;
    margin-left: 1.5rem !important;
    flex-direction: column !important;
    display: flex !important;
    border: 1px solid #dee2e6;
    border-top: 6px solid #1a5970;
  `;

const ModalContainer = styled.div`
  padding: 2.5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #dee2e6;
  border-top: 6px solid #1a5970;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  div {
    margin: 1rem 0;
  }

  form {
    input {
      margin-left: 1.5rem;
      width: 75%;
      padding: 0.5rem;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      
      &:focus {
        border-color: #1a5970;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(26, 89, 112, 0.25);
      }
    }
  }
`;

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0.25rem;
  color: #6c757d;
  border: 1px solid #6c757d;
  padding: 0.5rem 0.75rem;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  font-size: 0.95rem;
  
  &:hover {
    background-color: #a3bcc5;
    color: #333;
    border-color: #1a5970;
  }
  
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(26, 89, 112, 0.25);
  }
  
  &:active {
    background-color: #1a5970;
    color: white;
  }
  
  /* Disabled state */
  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    pointer-events: none;
  }

  ${props => props.active && `
    background-color: #e8f0f3;
    border-color: #1a5970;
    color: #1a5970;
    font-weight: 600;
  `}
`;

const ButtonGroup = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px dashed #eee;
  
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
`;

const LoadingSpinner = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 8px;
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
`;

// Custom modal styles
const customModalStyles = {
  modal: {
    borderRadius: '8px',
    maxWidth: '600px',
    width: '90%',
    padding: 0,
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)'
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.6)',
    backdropFilter: 'blur(3px)'
  },
  closeButton: {
    background: '#f5f5f5',
    borderRadius: '50%',
    border: 'none'
  }
};



export const ControlColumn = () => {
  
   const [activeSort, setActiveSort] = useState("ORDER_ASC");
  //React Modal Required Hooks
  const [openAddBookModal, setOpenAddBookModal] = useState(false);
  const onOpenAddBookModal = () => setOpenAddBookModal(true);
  const onCloseAddBookModal = () => setOpenAddBookModal(false);

  const [openSyncGoodreadsModal, setOpenSyncGoodreadsModal] = useState(false);
  const onOpenSyncGoodreadsModal = () => setOpenSyncGoodreadsModal(true);
  const onCloseSyncGoodreadsModal = () => setOpenSyncGoodreadsModal(false);
  //End React Modal Required Hooks

    // Add this error state variable
  const [errorMsg, setErrorMsg] = useState(null);

  const [loading, toggleLoading] = useState(false);

  // const GRBooks = dumb.GoodreadsResponse.reviews.review;

  const {
    setSorting,
    setDragable,
    bookList,
    setBookList,
    user,
    sortUserBooks,
  } = useContext(LocalStateContext);

    // Updated sync function with error handling
  const handleSync = async () => {
    toggleLoading(true);
    setErrorMsg(null); // Clear any previous errors
    
    try {
      await syncGoodReads(user.id); // Use user.id not GRBooks
      onCloseSyncGoodreadsModal();
    } catch (error) {
      console.error("GoodReads sync error:", error);
      setErrorMsg("Failed to sync with GoodReads. Please try again later.");
    } finally {
      toggleLoading(false);
    }
  };

    // Modify your sortUserBooks wrapper to update activeSort state
  const handleSort = (sortMethod) => {
    sortUserBooks(sortMethod);
    setActiveSort(sortMethod);
    
    // If switching to reading order, enable drag
    if (sortMethod === "ORDER_ASC") {
      setDragable(true);
    } else {
      setDragable(false);
    }
  };


  // const sync = async () => {
  //   toggleLoading(true);

  //   await syncGoodReads(GRBooks);

  //   toggleLoading(false);
  // };




  return (
    <>
      <ColumnContainer>
        {/* Add Book Modal */}
        <Modal
          open={openAddBookModal}
          onClose={() => setOpenAddBookModal(false)}
          center
          styles={customModalStyles}
          classNames={{
            modal: 'custom-modal',
          }}
        >
          <ModalContainer>
            <ModalAddBookForm
              scrapeBookGoodReads={scrapeBookGoodReads}
              addBook={addBook}
              setBookList={setBookList}
              setSorting={setSorting}
              setDragable={setDragable}
              bookList={bookList}
              user={user}
              onClose={() => setOpenAddBookModal(false)}
            />
          </ModalContainer>
        </Modal>

        {/* Sync GoodReads Modal */}
        <Modal
          open={openSyncGoodreadsModal}
          onClose={() => setOpenSyncGoodreadsModal(false)}
          center
          styles={customModalStyles}
        >
          <ModalContainer>
            <div className="modal-header">
              <h3>Sync With GoodReads</h3>
            </div>
            <div className="modal-body">
              <p>
                Note this is an experimental feature. It is best used to seed a
                booklist. Once you have already established an ordered booklist
                and saved, it is recommended not to re-sync.
              </p>
              {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
              <p><strong>Are you sure you would like to sync?</strong></p>
            </div>
            <div className="modal-footer">
              <ButtonGroup>
                <ControlButton
    onClick={handleSync}  // Use the handleSync function
    disabled={loading}
                >
                  {loading ? (
                    <>
                      <LoadingSpinner /> Syncing...
                    </>
                  ) : (
                    <>
                      <FiRefreshCw style={{ marginRight: '8px' }} /> Sync GoodReads
                    </>
                  )}
                </ControlButton>
                <ControlButton
                  onClick={() => setOpenSyncGoodreadsModal(false)}
                  style={{ marginTop: '8px' }}
                >
                  Cancel
                </ControlButton>
              </ButtonGroup>
            </div>
          </ModalContainer>
        </Modal>

        <ButtonGroup>
          <h3>Edit noveList</h3>
          <ControlItem>
            <ControlButton onClick={() => setOpenAddBookModal(true)}>
              <FiPlus style={{ marginRight: '8px' }} /> Add Book
            </ControlButton>
          </ControlItem>
          <ControlItem>
            <ControlButton
              onClick={() => {
                handleSort("ORDER_ASC");
                setDragable(true);
              }}
              active={activeSort === "ORDER_ASC"}
            >
              <FiList style={{ marginRight: '8px' }} /> Edit Reading Order
            </ControlButton>
          </ControlItem>
          <ControlItem>
            <ControlButton 
              onClick={() => setOpenSyncGoodreadsModal(true)}
              disabled={loading}
            >
              {loading ? (
                <>
                  <LoadingSpinner /> Syncing...
                </>
              ) : (
                <>
                  <FiRefreshCw style={{ marginRight: '8px' }} /> Sync With GoodReads
                </>
              )}
            </ControlButton>
          </ControlItem>
        </ButtonGroup>
        
        <ButtonGroup>
          <h3>Sort Books</h3>
        {/* Sort buttons remain the same but wrapped in ButtonGroup */}
      
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("averageRating_DESC");
            }}
            active={activeSort === "averageRating_DESC"}
          >
           
<span style={{ marginRight: '8px' }}>⭐</span> Best Rated
          </ControlButton>
        </ControlItem>
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("averageRating_ASC");
            }}
            active={activeSort === "averageRating_ASC"}
          >
<span style={{ marginRight: '8px' }}>☆</span> Worst Rated
          </ControlButton>
        </ControlItem>
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("title_ASC");
            }}
            active={activeSort === "title_ASC"}
          >
            <span style={{ marginRight: '8px' }}>🔤</span> A - Z
          </ControlButton>
        </ControlItem>
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("title_DESC");
            }}
            active={activeSort === "title_DESC"}
          >
              <span style={{ marginRight: '8px' }}>🔤</span> Z - A
          </ControlButton>
        </ControlItem>
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("pages_DESC");
            }}
            active={activeSort === "pages_DESC"}
          >
           <span style={{ marginRight: '8px' }}>📚</span> Longest - Shortest
          </ControlButton>
        </ControlItem>
        <ControlItem>
          <ControlButton
            onClick={() => {
              handleSort("pages_ASC");
            }}
            active={activeSort === "pages_ASC"}
          >
           <span style={{ marginRight: '8px' }}>📕</span> Shortest - Longest
          </ControlButton>
        </ControlItem>
        </ButtonGroup>
      </ColumnContainer>

    </>
  );
};
