import React, { useContext } from "react";
import moment from "moment";

import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";
import BookMark from "../Icons/Bookmark";
import Trophy from "../Icons/Trophy";
import Pages from "../Icons/Pages";
import Book from "../Icons/Book";
import Calendar from "../Icons/Calendar";
import Exclamation from "../Icons/Exclamation";

// Update the StatsContainer component
const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 20px;
  margin-top: 3em;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top: 6px solid #1a5970;
  transition: all 0.3s ease-in-out;
  
  @media (max-width: 768px) {
    padding: 15px;
    margin-top: 2em;
    max-width: none;
    
    /* Add these properties for when the component is in a modal */
    &.in-modal {
      max-height: 80vh; /* Limit height in modal */
      overflow-y: auto; /* Enable vertical scrolling */
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
      scrollbar-width: thin; /* For Firefox */
      scrollbar-color: #1a5970 #f0f0f0; /* For Firefox */
      
      /* Customize scrollbar for WebKit browsers */
      &::-webkit-scrollbar {
        width: 8px;
      }
      
      &::-webkit-scrollbar-track {
        background: #f0f0f0;
        border-radius: 4px;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #1a5970;
        border-radius: 4px;
      }
    }
  }
`;

const StatsItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StatsItem = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: auto;
  gap: 15px;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  
  @media (max-width: 768px) {
    grid-template-columns: 35px 1fr;
    gap: 10px;
  }
  
  h5 {
    margin: 0 0 5px 0;
    font-size: 16px;
    font-weight: 600;
    color: #1a5970; /* Brand color for headings */
    letter-spacing: 0.015em; /* Slightly improved readability */
    
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  
  p {
    margin: 0;
    font-size: 15px;
    color: #4a4a4a; /* Slightly lighter than black for body text */
    font-weight: 400; /* Normal weight for body text */
    
    /* Style for emphasized text using the font-semibold class */
    &.font-semibold {
      font-weight: 600;
      color: #333; /* Darker than regular text */
    }
    
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }
`;

const ItemIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  
  @media (max-width: 768px) {
  transition: all 0.3s ease-in-out;
    height: 35px;
    width: 35px;
  }
  
  ${({ color }) => {
    switch (color) {
      case "one":
        return "fill: #fff; background: #1a7065;";
      case "two":
        return "fill: #fff; background: #1a6a70;";
      case "three":
        return "fill: #fff; background: #1a5970;";
      case "four":
        return "fill: #fff; background: #1a4870;";
      case "five":
        return "fill: #fff; background: #1a3770;";
      case "warning":
        return "fill: #fff; background: #ffc107;";
      default:
        return "fill: #fff; background: #1a4870;";
    }
  }};
  
  svg {
    width: 20px;
    height: 20px;
    transition: all 0.3s ease-in-out;
    
    @media (max-width: 768px) {
    transition: all 0.3s ease-in-out;
      width: 18px;
      height: 18px;
    }
  }
`;

const ChallengeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
`;
const ChallengeBox = styled.div`
  border: 2px solid #ffc107;
  border-radius: 8px;
  padding: 15px;
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 15px;
  background-color: rgba(255, 193, 7, 0.05);
  transition: all 0.3s ease-in-out;
  
  @media (max-width: 768px) {
  transition: all 0.3s ease-in-out;
    grid-template-columns: 35px 1fr;
    padding: 12px;
  }
`;

const ChallengeIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const ChallengeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChallengeTextTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #1a5970;
  letter-spacing: 0.015em;
  margin-bottom: 2px; /* Add a bit more space below title */
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const ChallengeTextBody = styled.div`
  color: #4a4a4a;
  font-size: 15px;
  line-height: 1.3;
  transition: all 0.3s ease-in-out;
  
  @media (max-width: 768px) {
  transition: all 0.3s ease-in-out;
    font-size: 13px;
  }
`;

export const StatsColumn = ({inModal = false}) => {
  const currentYear = moment().year();
  const daysToNewYear =
    Number(moment().diff(`${currentYear + 1}-01-01`, "days")) * -1;

  const { totalPages, booksRead, bookList, bookGoal } =
    useContext(LocalStateContext);

  let extraReading;
  if (bookList[bookGoal]) {
    daysToNewYear > 0
      ? (extraReading = (bookList[bookGoal].book.pages / daysToNewYear).toFixed(
          0
        ))
      : (extraReading = bookList[bookGoal].book.pages.toFixed(0));
  }

  return (
    <div>
    <StatsContainer>
      <StatsItemsContainer className={inModal ? 'in-modal' : ''}>
        <StatsItem>
          <ItemIcon color="one">
            <Trophy />
          </ItemIcon>
          <div>
            <h5>Reading Goal For {currentYear}:</h5>
            <p className="font-semibold">
              {bookGoal} {bookGoal !== 1 ? "books" : "book"}.
            </p>
          </div>
        </StatsItem>
          <StatsItem>
            <ItemIcon color="two">
              <Book />
            </ItemIcon>
            <div>
              <h5>Books Read This Year:</h5>
              {booksRead !== 1 ? (
                <p className="font-semibold">{booksRead} books.</p>
              ) : (
                <p className="font-semibold">{booksRead} book.</p>
              )}
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="three">
              <Pages />
            </ItemIcon>
            <div>
              <h5>Pages Left To Read:</h5>
              <p className="font-semibold">
                {bookGoal <= bookList.length
                  ? totalPages > 0
                    ? `${totalPages} pages.`
                    : `${totalPages} page.`
                  : `Add more books to calculate.`}
              </p>
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="four">
              <Calendar />
            </ItemIcon>
            <div>
              <h5>Days Left In Year:</h5>
              {daysToNewYear > 0 ? (
                <p className="font-semibold">{daysToNewYear} days.</p>
              ) : (
                <p className="font-semibold">{daysToNewYear} day.</p>
              )}
            </div>
          </StatsItem>
          <StatsItem>
            <ItemIcon color="five">
              <BookMark />
            </ItemIcon>
            <div>
              <h5>Pages Per Day to Reach Goal:</h5>
              <p className="font-semibold">
                {bookGoal <= bookList.length
                  ? `${Math.round(totalPages / daysToNewYear)} pages per day.`
                  : `Add more books to calculate.`}
              </p>
            </div>
          </StatsItem>
        </StatsItemsContainer>
        {bookGoal <= bookList.length && (
          <ChallengeContainer>
            <ChallengeBox>
              <ChallengeIconContainer>
                <ItemIcon color="warning">
                  <Exclamation />
                </ItemIcon>
                <div></div>
              </ChallengeIconContainer>
              <ChallengeTextContainer>
                <ChallengeTextTitle>
                  <div>Like to challenge yourself?</div>
                </ChallengeTextTitle>
                <ChallengeTextBody>
                  Read {extraReading} more pages a day, and you will finish 1
                  extra book this year.
                </ChallengeTextBody>
              </ChallengeTextContainer>
            </ChallengeBox>
          </ChallengeContainer>
        )}
      </StatsContainer>
    </div>
  );
};
