import React, { useState } from "react";
import { styled } from "styled-components";
import toast from "react-hot-toast";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormHeader = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  
  h4 {
    color: #1a5970;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #667c8a;
    font-size: 1rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid ${props => props.error ? '#dc3545' : '#ced4da'};
  border-radius: 0.375rem;
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
  &:focus {
    outline: none;
    border-color: #1a5970;
    box-shadow: 0 0 0 0.2rem rgba(26, 89, 112, 0.25);
  }
`;

const HelpText = styled.div`
  color: ${props => props.error ? '#dc3545' : '#6c757d'};
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
  font-weight: 500;
  font-size: 1rem;
  border: none;
  background-color: #1a5970;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
  
  &:hover {
    background-color: #146280;
  }
  
  &:disabled {
    background-color: #a3bcc5;
    cursor: not-allowed;
  }
  
  .spinner {
    margin-right: 0.5rem;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const BookPreview = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  background-color: #f8f9fa;
`;

const PreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  h5 {
    color: #1a5970;
    margin: 0;
  }
  
  button {
    background: none;
    border: none;
    color: #6c757d;
    cursor: pointer;
    
    &:hover {
      color: #1a5970;
    }
  }
`;

const PreviewContent = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 1rem;
`;

const BookCover = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 2/3;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BookDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  
  .title {
    font-weight: 600;
    font-size: 1.125rem;
  }
  
  .author {
    color: #495057;
    font-style: italic;
  }
  
  .meta {
    color: #6c757d;
    font-size: 0.875rem;
    display: flex;
    gap: 0.75rem;
    margin-top: 0.25rem;
  }
`;

const ModalAddBookForm = ({
  scrapeBookGoodReads,
  addBook,
  setBookList,
  setSorting,
  setDragable,
  bookList,
  user,
  onClose
}) => {
  const [goodReadsURL, setGoodReadsURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [previewBook, setPreviewBook] = useState(null);

  const validateURL = (url) => {
    // Check if URL is a valid GoodReads URL
    const goodreadsRegex = /^https:\/\/(www\.)?goodreads\.com\/book\/show\/\d+/i;
    return goodreadsRegex.test(url);
  };

  const handleURLChange = (e) => {
    setGoodReadsURL(e.target.value);
    if (error) setError(null);
  };

  const handlePreview = async () => {
    // First validate the URL
    if (!goodReadsURL) {
      setError("Please enter a GoodReads book URL");
      return;
    }
    
    if (!validateURL(goodReadsURL)) {
      setError("Please enter a valid GoodReads book URL (e.g., https://www.goodreads.com/book/show/123)");
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      // Fetch book data but don't add to list yet
      const scrapedBookResponse = await scrapeBookGoodReads(goodReadsURL, bookList.length);
      
      if (!scrapedBookResponse?.payload?.book) {
        throw new Error("Unable to retrieve book information");
      }
      
      setPreviewBook(scrapedBookResponse.payload.book);
    } catch (err) {
      console.error(err);
      setError("Failed to retrieve book data. Please check the URL and try again.");
    } finally {
      setIsLoading(false);
    }
  };

const handleAddBook = async () => {
  if (!previewBook) return;
  
  setIsLoading(true);
  try {
    // Extract just the user ID from the user object
    const userId = typeof user === 'object' ? user.id : user;
    
    // Add book to database with just the ID
    const newBook = await addBook({
      bookData: previewBook,
      user: userId, // Send only the ID, not the entire object with token
      length: bookList.length,
    });
    
    if (!newBook?.payload?.book) {
      throw new Error("Failed to add book to your list");
    }

    // Update local state
    setBookList([...bookList, { ...newBook.payload.book }]);
    setSorting("ORDER_ASC");
    setDragable(true);
    
    // Show success message
    toast.success(`Added "${previewBook.title}" by ${previewBook.author} to your reading list`);
    
    // Clear form and close modal
    setGoodReadsURL("");
    setPreviewBook(null);
    onClose();
  } catch (err) {
    console.error(err);
    setError(`Error adding book: ${err.message}`);
    
    // More specific error message for debugging
    if (err.message.includes('CastError') || err.message.includes('ObjectId')) {
      setError("There was a problem with your user credentials. Please try logging in again.");
    }
  } finally {
    setIsLoading(false);
  }
};

  const cancelPreview = () => {
    setPreviewBook(null);
  };

  return (
    <FormContainer>
      <FormHeader>
        <h4>Add Book from GoodReads</h4>
        <p>Enter a GoodReads book URL to add it to your reading list</p>
      </FormHeader>
      
      {!previewBook ? (
        <>
          <FormGroup>
            <label htmlFor="inputURL"><strong>GoodReads URL:</strong></label>
            <Input
              type="text"
              id="inputURL"
              placeholder="https://www.goodreads.com/book/show/..."
              value={goodReadsURL}
              onChange={handleURLChange}
              error={!!error}
              disabled={isLoading}
            />
            <HelpText error={!!error}>{error || "Example: https://www.goodreads.com/book/show/5107.The_Catcher_in_the_Rye"}</HelpText>
          </FormGroup>
          
          <ActionButton onClick={handlePreview} disabled={isLoading || !goodReadsURL}>
            {isLoading && <span className="spinner">⟳</span>}
            {isLoading ? "Retrieving Book Data..." : "Preview Book"}
          </ActionButton>
        </>
      ) : (
        <BookPreview show={!!previewBook}>
          <PreviewHeader>
            <h5>Book Preview</h5>
            <button onClick={cancelPreview}>← Back</button>
          </PreviewHeader>
          
          <PreviewContent>
            <BookCover src={previewBook.image} alt={`Cover of ${previewBook.title}`} />
            <BookDetails>
              <div className="title">{previewBook.title}</div>
              <div className="author">by {previewBook.author}</div>
              <div className="meta">
                <span>{previewBook.pages} pages</span>
                <span>{previewBook.averageRating}★ ({previewBook.ratingsCount} ratings)</span>
              </div>
            </BookDetails>
          </PreviewContent>
          
          <ActionButton onClick={handleAddBook} disabled={isLoading}>
            {isLoading && <span className="spinner">⟳</span>}
            {isLoading ? "Adding to Your List..." : "Add to Reading List"}
          </ActionButton>
        </BookPreview>
      )}
    </FormContainer>
  );
};

export default ModalAddBookForm;