import React, { createContext, useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import toast from "react-hot-toast";

// API Mutations
import { deleteBookMutation } from "../Mutations/DeleteBook";
import { updateBookOrder } from "../Mutations/UpdateBookOrder";
import { markBookAsReadMutation } from "../Mutations/MarkBookAsRead";
import { setReadingGoal } from "../Mutations/SetReadingGoal";
import { updateUserDataMutation } from "../Mutations/UpdateUserDataMutation";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Create context
const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

/**
 * Novel List State Provider Component
 * Manages global state for the application
 */
const NoveListStateProvider = ({ children }) => {
  // ===============================
  // STATE DEFINITIONS
  // ===============================
  const [user, setUser] = useState(null);
  const [booksRead, setBooksRead] = useState(0);
  const [finishedBooks, setFinishedBooks] = useState({});
  const [bookGoal, setBookGoal] = useState(null);
  const [bookGoals, setBookGoals] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [shadowList, setShadowList] = useState([]);
  const [dragable, setDragable] = useState(true);
  const [sorting, setSorting] = useState("ORDER_ASC");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // ===============================
  // COMPUTED VALUES
  // ===============================
  const currentYear = useMemo(() => moment().year(), []);
  
  // Calculate total pages to read for books in the reading goal
  const totalPages = useMemo(() => {
    if (!bookList?.length || !bookGoal) return 0;
    
    const remainingBooks = bookGoal > booksRead 
      ? bookGoal - booksRead
      : bookGoal;
      
    const sliceBooks = bookList.slice(0, remainingBooks);
    
    return sliceBooks.reduce((total, item) => 
      total + (item.book?.pages || 0), 0);
  }, [bookList, bookGoal, booksRead]);
// Update these parts in your LocalStateContext provider

// ===============================
// USER AUTHENTICATION
// ===============================
// Check for user in local storage on initial load
useEffect(() => {
  if (localStorage.getItem("user")) {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      
      // Check if token exists and is still valid
      if (userData && userData.token) {
        // You could verify token expiration here
        setUser(userData);
      } else {
        // Invalid storage data
        logout();
      }
    } catch (err) {
      console.error("Failed to parse user from localStorage:", err);
      logout();
    }
  }
}, []);

// Logout user function
const logout = useCallback(() => {
  localStorage.removeItem("user");
  setUser(null);
  // Could also redirect to login page
}, []);

// Add authentication headers to API calls
useEffect(() => {
  if (!user) return;
  
  const fetchUserData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(`${BACKEND_URL}/readingDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}` // Add token to requests
        },
        body: JSON.stringify({ payload: { user: user.id } }),
      });
      
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          // Token expired or invalid
          logout();
          throw new Error("Session expired. Please log in again.");
        }
        throw new Error(`API error: ${response.status}`);
      }
      
      const data = await response.json();
      processUserData(data);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching user data:", err);
    } finally {
      setLoading(false);
    }
  };
  
  fetchUserData();
}, [user, currentYear, logout]);


  // ===============================
  // API INTERACTIONS
  // ===============================
  // Fetch user reading details
  useEffect(() => {
    if (!user) return;
    
const fetchUserData = async () => {
  try {
    setLoading(true);
    setError(null);
    
    console.log("Fetching user data with token:", user.token);
    
    const response = await fetch(`${BACKEND_URL}/readingDetails`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user.token}`
      },
      body: JSON.stringify({ payload: { user: user.id } }),
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      console.error("API error response:", errorData);
      
      if (response.status === 401 || response.status === 403) {
        logout();
        throw new Error("Session expired. Please log in again.");
      }
      throw new Error(`API error: ${response.status} - ${errorData.message || 'Unknown error'}`);
    }
    
    const data = await response.json();
    console.log("Received user data:", data);
    processUserData(data);
  } catch (err) {
    setError(err.message);
    console.error("Error fetching user data:", err);
  } finally {
    setLoading(false);
  }
};
    
    fetchUserData();
  }, [user, currentYear]);

  // Process and set user data from API response
  const processUserData = useCallback((data) => {
    if (!data?.payload) return;
    
    const { books, readingGoals, finishedBooks } = data.payload;
    
    // Process books
    if (books?.length) {
      setBookList(books.sort((a, b) => a.order - b.order));
    }
    
    // Process reading goals
    if (readingGoals) {
      setBookGoals(readingGoals);
      
      const currentGoal = readingGoals.find(
        goal => goal.year === currentYear
      )?.goal;
      
      if (currentGoal) {
        setBookGoal(currentGoal);
      }
    }
    
    // Process finished books
    if (finishedBooks) {
      setFinishedBooks(finishedBooks);
      
      const currentYearBooks = finishedBooks[currentYear] || [];
      setBooksRead(currentYearBooks.length);
    } else {
      setBooksRead(0);
    }
  }, [currentYear]);

  // Update user data from server
  const updateUserData = useCallback(async () => {
    if (!user) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const data = await updateUserDataMutation(user);
      processUserData(data);
      
      return data;
    } catch (err) {
      setError(err.message);
      console.error("Error updating user data:", err);
    } finally {
      setLoading(false);
    }
  }, [user, processUserData]);

  // ===============================
  // BOOK MANAGEMENT FUNCTIONS
  // ===============================
  // Add reading goal
  const addReadingGoal = useCallback(async ({ goal, userId }) => {
    if (!userId) return;
    
    try {
      const newGoal = await setReadingGoal({ goal, userId });
      setBookGoal(newGoal);
      return newGoal;
    } catch (err) {
      setError(err.message);
      console.error("Error setting reading goal:", err);
    }
  }, []);

  // Sort user books
  const sortUserBooks = useCallback((sortBy) => {
    setSorting(sortBy);
  }, []);

  // Handle book sorting effect
  useEffect(() => {
    if (!bookList?.length) return;
    
    let sortedBooks;
    
    switch (sorting) {
      case "ORDER_ASC":
        // Default ordering, no need to change shadowList
        setDragable(true);
        return;
        
      case "averageRating_DESC":
        sortedBooks = [...bookList].sort(
          (a, b) => (b.book.averageRating || 0) - (a.book.averageRating || 0)
        );
        break;
        
      case "averageRating_ASC":
        sortedBooks = [...bookList].sort(
          (a, b) => (a.book.averageRating || 0) - (b.book.averageRating || 0)
        );
        break;
        
      case "title_DESC":
        sortedBooks = [...bookList].sort((a, b) => {
          const titleA = (a.book.title || "").toLowerCase();
          const titleB = (b.book.title || "").toLowerCase();
          return titleB.localeCompare(titleA);
        });
        break;
        
      case "title_ASC":
        sortedBooks = [...bookList].sort((a, b) => {
          const titleA = (a.book.title || "").toLowerCase();
          const titleB = (b.book.title || "").toLowerCase();
          return titleA.localeCompare(titleB);
        });
        break;
        
      case "pages_ASC":
        sortedBooks = [...bookList].sort(
          (a, b) => (a.book.pages || 0) - (b.book.pages || 0)
        );
        break;
        
      case "pages_DESC":
        sortedBooks = [...bookList].sort(
          (a, b) => (b.book.pages || 0) - (a.book.pages || 0)
        );
        break;
        
      default:
        sortedBooks = [...bookList];
    }
    
    setDragable(false);
    setShadowList(sortedBooks);
  }, [sorting, bookList]);

// Update the deleteBookFromUser function in your LocalState context
const deleteBookFromUser = async ({ bookId, userId }) => {
  try {
    // Ensure we're using just the ID string
    const cleanUserId = typeof userId === 'object' ? userId.id : userId;
    
    const response = await fetch(`${BACKEND_URL}/deletebookfromuser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          bookId,
          userId: cleanUserId,
        },
      }),
    });
    
    const data = await response.json();
    
    if (data.payload && data.payload.success) {
      // Update the local state to remove the book
      setBookList(prev => prev.filter(item => item.book._id !== bookId));
    }
    
    return data;
  } catch (error) {
    console.error("Error deleting book:", error);
    throw error;
  }
};

  // Mark book as complete
  const markBookAsComplete = useCallback(({ id, user }) => {
      // Extract just the ID if user is an object
  const cleanUserId = typeof user === 'object' ? user.id : user;
    if (!cleanUserId  || !user) return;
    
    try {
      markBookAsReadMutation({
        userId: cleanUserId ,
        books: bookList,
        bookId: id,
        currentYear: moment().year(),
      });
    } catch (err) {
      setError(err.message);
      console.error("Error marking book as complete:", err);
    }
  }, [bookList]);

  // Send book to top of the list
  const sendBookToTop = useCallback(({ bookId, userId }) => {
    const cleanUserId = typeof userId === 'object' ? userId.id : userId;
    if (!bookId || !cleanUserId || !bookList?.length) return;
    
    try {
      const reorderedBooks = [...bookList];
      let bookIndex = -1;
      
      for (let i = 0; i < reorderedBooks.length; i++) {
        if (reorderedBooks[i].book._id === bookId) {
          bookIndex = i;
          break;
        }
      }
      
      if (bookIndex > -1) {
        const [book] = reorderedBooks.splice(bookIndex, 1);
        reorderedBooks.unshift(book);
        updateBookList(reorderedBooks);
      }
    } catch (err) {
      setError(err.message);
      console.error("Error sending book to top:", err);
    }
  }, [bookList]);

  // Send book to bottom of the list
  const sendBookToBottom = useCallback(({ bookId, userId }) => {
      const cleanUserId = typeof userId === 'object' ? userId.id : userId;
    if (!bookId || !cleanUserId || !bookList?.length) return;
    
    try {
      const reorderedBooks = [...bookList];
      let bookIndex = -1;
      
      for (let i = 0; i < reorderedBooks.length; i++) {
        if (reorderedBooks[i].book._id === bookId) {
          bookIndex = i;
          break;
        }
      }
      
      if (bookIndex > -1) {
        const [book] = reorderedBooks.splice(bookIndex, 1);
        reorderedBooks.push(book);
        updateBookList(reorderedBooks);
      }
    } catch (err) {
      setError(err.message);
      console.error("Error sending book to bottom:", err);
    }
  }, [bookList]);

  // Update the book order
  const updateBookList = useCallback((books) => {
    if (!books || !user) return;
    
    try {
      setBookList(books);
      updateBookOrder({ user, books });
    } catch (err) {
      setError(err.message);
      console.error("Error updating book list:", err);
    }
  }, [user]);

  // ===============================
  // CONTEXT PROVIDER
  // ===============================
  const contextValue = {
    // User data
    user,
    setUser,
    logout,
    
    // Book data
    bookList,
    setBookList,
    shadowList,
    setShadowList,
    finishedBooks,
    
    // Reading goals
    bookGoal,
    setBookGoal,
    bookGoals,
    booksRead,
    totalPages,
    
    // UI state
    loading,
    error,
    dragable,
    setDragable,
    sorting,
    setSorting,
    
    // Functions
    addReadingGoal,
    updateBookList,
    markBookAsComplete,
    sendBookToBottom,
    sendBookToTop,
    sortUserBooks,
    updateUserData,
    deleteBookFromUser,
  };

  return (
    <LocalStateProvider value={contextValue}>
      {children}
    </LocalStateProvider>
  );
};

export { NoveListStateProvider, LocalStateContext };