import React, { useContext, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { DragList } from "./DragList";
import { StatsColumn } from "./StatsColumn";
import { ControlColumn } from "./ControlColumn";
import { LocalStateContext } from "../State/LocalState";
import SetGoal from "./SetGoal";
import styled from "styled-components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ScrollTopButton from "./ScrollTopButton";



const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 10px;
  
  @media (min-width: 992px) {
    grid-template-columns: 1fr 2fr 1fr;
  }
  
  @media (min-width: 1302px) {
    grid-template-columns: 1fr 1fr 2fr;
  }
  
  @media (min-width: 1550px) {
    grid-template-columns: 1fr 3fr 2fr;
  }
`;

const ResponsiveColumn = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`;

const ControlToggleButton = styled.button`
  position: fixed;
  top: 80px;
  left: 20px;
  z-index: 999;
  background-color: #1a5970;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: none;
  
  @media (max-width: 991px) {
    display: block;
  }
  
  &:hover {
    background-color: #124354;
  }
`;

const StatsToggleButton = styled.button`
  position: fixed;
  top: 80px;
  right: 20px;
  z-index: 999;
  background-color: #1a5970;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: none;
  
  @media (max-width: 991px) {
    display: block;
  }
  
  &:hover {
    background-color: #124354;
  }
`;

const ModalContainer = styled.div`
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
`;

export const Main = () => {
  let location = useLocation();
  const { user, bookGoal } = useContext(LocalStateContext);
  const [controlModalOpen, setControlModalOpen] = useState(false);
  const [statsModalOpen, setStatsModalOpen] = useState(false);

  return (
    <div>
      <Routes>
        <Route
          path="/auth"
          element={
            !user && <Navigate to="/login" state={{ from: location }} replace />
          }
        />
      </Routes>
      
      {/* Toggle buttons for smaller screens */}
      <ControlToggleButton onClick={() => setControlModalOpen(true)}>
        Show Controls
      </ControlToggleButton>
      
      <StatsToggleButton onClick={() => setStatsModalOpen(true)}>
        Show Stats
      </StatsToggleButton>
      
      {/* Control Modal for small screens */}
      <Modal
        open={controlModalOpen}
        onClose={() => setControlModalOpen(false)}
        center
      >
        <ModalContainer>
          <ControlColumn />
        </ModalContainer>
      </Modal>
      
      {/* Stats Modal for small screens */}
      <Modal
        open={statsModalOpen}
        onClose={() => setStatsModalOpen(false)}
        center
      >
        <ModalContainer>
          {bookGoal && <StatsColumn  inModal={true}/>}
          {!bookGoal && <SetGoal />}
        </ModalContainer>
      </Modal>
      
      <MainGrid>
        {/* Control Column - visible only on larger screens */}
        <ResponsiveColumn>
          <ControlColumn />
        </ResponsiveColumn>
        
        {/* DragList - always visible */}
        <div>
          <DragList />
        </div>
        
        {/* Stats Column - visible only on larger screens */}
        <ResponsiveColumn>
          {bookGoal && <StatsColumn />}
          {!bookGoal && <SetGoal />}
        </ResponsiveColumn>
      </MainGrid>
      <ScrollTopButton/>
    </div>
  );
};