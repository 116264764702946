import React from "react";
import styled from "styled-components";
import { FiAlertTriangle, FiX, FiCheck, FiTrash2 } from "react-icons/fi";

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 1rem;
  animation: fadeIn 0.2s ease;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;

const DialogBox = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  animation: scaleIn 0.2s ease;
  overflow: hidden;
  
  @keyframes scaleIn {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
`;

const DialogHeader = styled.div`
  background: #f8f9fa;
  padding: 1.25rem 1.5rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  
  svg {
    font-size: 1.5rem;
    color: #dc3545;
    margin-right: 1rem;
  }
`;

const DialogTitle = styled.h4`
  margin: 0;
  color: #343a40;
  font-size: 1.25rem;
  font-weight: 600;
`;

const DialogContent = styled.div`
  padding: 1.5rem;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  
  strong {
    color: #212529;
    font-weight: 600;
  }
`;

const DialogActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1.5rem 1.5rem;
  gap: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:focus {
    outline: none;
  }
`;

const CancelButton = styled(Button)`
  background: #f8f9fa;
  color: #495057;
  border: 1px solid #ced4da;
  
  &:hover {
    background: #e9ecef;
  }
  
  &:active {
    background: #dee2e6;
  }
`;

const DeleteButton = styled(Button)`
  background: #dc3545;
  color: white;
  
  &:hover {
    background: #c82333;
    box-shadow: 0 2px 5px rgba(220, 53, 69, 0.2);
  }
  
  &:active {
    background: #bd2130;
    box-shadow: none;
  }
`;

const ConfirmDialog = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title = "Confirm Action",
  message,
  itemName,
  confirmText = "Delete",
  cancelText = "Cancel",
}) => {
  if (!isOpen) return null;
  
  return (
    <DialogOverlay onClick={(e) => {
      // Close when clicking the overlay background
      if (e.target === e.currentTarget) onClose();
    }}>
      <DialogBox onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <FiAlertTriangle />
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <p>{message} <strong>{itemName}</strong>?</p>
          <p>This action cannot be undone.</p>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={onClose}>
            <FiX size={16} />
            {cancelText}
          </CancelButton>
          <DeleteButton onClick={onConfirm}>
            <FiTrash2 size={16} />
            {confirmText}
          </DeleteButton>
        </DialogActions>
      </DialogBox>
    </DialogOverlay>
  );
};

export default ConfirmDialog;