import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { LocalStateContext } from "../State/LocalState";
import { styled } from "styled-components";

// Page wrapper with title
const HistoryWrapper = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const HistoryTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  color: #1a5970;
  text-align: center;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HistorySubtitle = styled.p`
  font-size: 1.2rem;
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  color: #667c8a;
`;

// Improved grid with better spacing
const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 2rem;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1.25rem;
  }
`;

// Enhanced card with hover effects and statistics
const YearCard = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  transition: all 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTop = styled.div`
  background-color: #1a5970;
  padding: 1.5rem 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Year = styled.h2`
  font-size: 2.2rem;
  font-weight: 700;
  color: white;
  margin: 0;
`;

const CardBottom = styled.div`
  background-color: white;
  padding: 1.25rem 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
  border-top: none;
`;

const BookCount = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
`;

const BookLabel = styled.div`
  font-size: 1rem;
  color: #667c8a;
  margin-top: 0.25rem;
`;

// Stats section
const StatsSection = styled.div`
  margin: 3rem 0;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StatCard = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
`;

const StatValue = styled.div`
  font-size: 2.2rem;
  font-weight: 700;
  color: #1a5970;
`;

const StatLabel = styled.div`
  font-size: 1rem;
  color: #667c8a;
  margin-top: 0.5rem;
`;

// No history state
const EmptyState = styled.div`
  text-align: center;
  padding: 4rem 1rem;
  color: #667c8a;
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1a5970;
  }
  
  p {
    font-size: 1.1rem;
    max-width: 500px;
    margin: 0 auto;
  }
`;

const ReadingHistory = () => {
  const { finishedBooks } = useContext(LocalStateContext);
  
  const years = useMemo(() => 
    Object.keys(finishedBooks).sort((a, b) => Number(b) - Number(a)),
    [finishedBooks]
  );
  
  // Calculate total books read
  const totalBooks = useMemo(() => 
    Object.values(finishedBooks).reduce((sum, yearBooks) => 
      sum + yearBooks.length, 0
    ),
    [finishedBooks]
  );
  
  // Calculate most productive year
  const mostProductiveYear = useMemo(() => {
    if (years.length === 0) return null;
    
    return years.reduce((best, year) => {
      if (!best || finishedBooks[year].length > finishedBooks[best].length) {
        return year;
      }
      return best;
    }, null);
  }, [years, finishedBooks]);
  
  // Calculate average books per year
  const avgBooksPerYear = useMemo(() => {
    if (years.length === 0) return 0;
    return (totalBooks / years.length).toFixed(1);
  }, [totalBooks, years.length]);

  if (years.length === 0) {
    return (
      <HistoryWrapper>
        <HistoryTitle>Reading History</HistoryTitle>
        <EmptyState>
          <h3>You haven't finished any books yet</h3>
          <p>When you mark books as read, they'll appear here organized by year.</p>
        </EmptyState>
      </HistoryWrapper>
    );
  }

  return (
    <HistoryWrapper>
      <HistoryTitle>Reading History</HistoryTitle>
      <HistorySubtitle>
        Explore your reading journey through the years. You've read {totalBooks} books since you started tracking.
      </HistorySubtitle>
      
      <StatsSection>
        <StatsGrid>
          <StatCard>
            <StatValue>{totalBooks}</StatValue>
            <StatLabel>Total Books Read</StatLabel>
          </StatCard>
          <StatCard>
            <StatValue>{mostProductiveYear}</StatValue>
            <StatLabel>Most Productive Year</StatLabel>
            <div style={{ fontSize: '0.9rem', marginTop: '0.5rem', color: '#667c8a' }}>
              {mostProductiveYear && `${finishedBooks[mostProductiveYear].length} books`}
            </div>
          </StatCard>
          <StatCard>
            <StatValue>{avgBooksPerYear}</StatValue>
            <StatLabel>Avg. Books Per Year</StatLabel>
          </StatCard>
        </StatsGrid>
      </StatsSection>
      
      <CardGrid>
        {years.map((year) => (
          <YearCard key={year} to={`/history/${year}`}>
            <CardTop>
              <Year>{year}</Year>
            </CardTop>
            <CardBottom>
              <BookCount>{finishedBooks[year].length}</BookCount>
              <BookLabel>
                {finishedBooks[year].length === 1 ? 'Book' : 'Books'} Read
              </BookLabel>
            </CardBottom>
          </YearCard>
        ))}
      </CardGrid>
    </HistoryWrapper>
  );
};

export default ReadingHistory;