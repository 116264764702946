import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LocalStateContext } from "../State/LocalState";
import { Routes, Route, Navigate } from "react-router-dom";
import { styled } from "styled-components";
import { FiBook, FiList, FiClock, FiGlobe, FiBookOpen, FiLogOut, FiMenu, FiX, FiUser } from "react-icons/fi";

// NavBar container with shadow and fixed positioning
const NavBarContainer = styled.nav`
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
`;

// Inner container for content with max width
const NavContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0.75rem 1.5rem;
  
  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`;

// Logo/brand styling
const BrandLink = styled(Link)`
  font-size: 1.5rem;
  font-weight: 700;
  color: #1867c0;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    color: #5e35b1;
  }
`;

// Navigation links container
const NavLinks = styled.div`
  display: flex;
  
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: ${props => props.isOpen ? '0' : '-100%'};
    width: 80%;
    max-width: 300px;
    height: 100vh;
    background-color: white;
    flex-direction: column;
    box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    padding: 1rem;
    z-index: 1001;
  }
`;

// Individual navigation link
const NavLink = styled(Link)`
  color: #555;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
  transition: all 0.2s;
  margin: 0 0.25rem;
  
  svg {
    margin-right: 0.5rem;
  }
  
  &:hover {
    background-color: rgba(24, 103, 192, 0.1);
    color: #1867c0;
  }
  
  &.active {
    background-color: #1867c0;
    color: white;
  }
  
  @media (max-width: 768px) {
    padding: 1rem;
    margin: 0.25rem 0;
    
    &:hover {
      background-color: #f5f7fa;
    }
  }
`;

// Logout button with specific styling
const LogoutButton = styled(NavLink)`
  color: #dc3545;
  
  &:hover {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545;
  }
  
  @media (max-width: 768px) {
    margin-top: auto; // Push to bottom in mobile menu
    border-top: 1px solid #eee;
  }
`;

// Mobile menu button
const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #1867c0;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.5rem;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Close button for mobile menu
const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  font-size: 1.5rem;
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// Mobile menu overlay
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${props => props.isOpen ? 'block' : 'none'};
  z-index: 1000;
`;

// Username display
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
  color: #1867c0;
  font-weight: 500;
  
  svg {
    margin-right: 0.5rem;
  }
  
  @media (max-width: 768px) {
    margin: 1rem 0;
    padding: 1rem;
    border-bottom: 1px solid #eee;
  }
`;

export const NavBar = () => {
  const location = useLocation();
  const { user, setUser } = useContext(LocalStateContext);
  const [redirect, setRedirect] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Handle scroll events to change navbar appearance
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Close menu when changing routes
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const logout = () => {
    localStorage.removeItem("user");
    setUser(false);
    setRedirect(true);
  };

  // Check if a link is active
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <NavBarContainer style={{ 
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      backgroundColor: scrolled ? 'white' : 'rgba(255, 255, 255, 0.98)'
    }}>
      <Routes>
        <Route
          path="/logout"
          element={
            redirect && (
              <Navigate to="/login" state={{ from: location }} replace />
            )
          }
        />
      </Routes>

      <NavContent>
        <BrandLink to={user ? "/main" : "/login"}>
          <FiBookOpen size={24} />
          <span>TheNovelist</span>
        </BrandLink>

        {/* Mobile menu toggle button */}
        <MenuButton onClick={() => setIsMenuOpen(true)}>
          <FiMenu size={24} />
        </MenuButton>

        {/* Navigation links */}
        <Overlay isOpen={isMenuOpen} onClick={() => setIsMenuOpen(false)} />
        
        <NavLinks isOpen={isMenuOpen}>
          <CloseButton onClick={() => setIsMenuOpen(false)}>
            <FiX size={24} />
          </CloseButton>

          {user && (
            <>
              <UserInfo>
                <FiUser size={16} />
                <span>{user.name}</span>
              </UserInfo>

              <NavLink to="/Shelf" className={isActive('/Shelf')}>
                <FiBook size={16} />
                <span>Shelf View</span>
              </NavLink>
              
              <NavLink to="/main" className={isActive('/main')}>
                <FiList size={16} />
                <span>List View</span>
              </NavLink>
              
              <NavLink to="/history" className={isActive('/history')}>
                <FiClock size={16} />
                <span>Reading History</span>
              </NavLink>
              
              <NavLink to="/originupdate" className={isActive('/originupdate')}>
                <FiGlobe size={16} />
                <span>Origin Update</span>
              </NavLink>
              
              <NavLink to="/goodreads-list" className={isActive('/goodreads-list')}>
                <FiBookOpen size={16} />
                <span>GoodReads List</span>
              </NavLink>

              <LogoutButton to="/login" onClick={logout}>
                <FiLogOut size={16} />
                <span>Logout</span>
              </LogoutButton>
            </>
          )}

          {!user && (
            <>
              <NavLink to="/login" className={isActive('/login')}>
                <FiUser size={16} />
                <span>Login</span>
              </NavLink>
              
              <NavLink to="/register" className={isActive('/register')}>
                <FiUser size={16} />
                <span>Register</span>
              </NavLink>
            </>
          )}
        </NavLinks>
      </NavContent>
    </NavBarContainer>
  );
};