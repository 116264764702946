
import React, { useState, useEffect, useCallback, useContext } from 'react';
import BookCoverImage from './BookCoverImage';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import Papa from 'papaparse';
import { LocalStateContext } from '../State/LocalState';
import { addBook } from '../Mutations/AddBook';
import Stars from './Stars';


const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const GOOGLE_BOOKS_API_KEY = ""
// 'AIzaSyCBFXKUYCNnvsa13RPwoD8gSaSNBMago48'; // You should move this to an env variable

// In your component, add this after the styled components:
const placeholderImage = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='300' viewBox='0 0 200 300'%3E%3Crect width='200' height='300' fill='%23f0f0f0'/%3E%3Ctext x='50%25' y='50%25' font-family='Arial' font-size='24' text-anchor='middle' fill='%23999999'%3ENo Cover%3C/text%3E%3C/svg%3E";

// Add a local cache for covers to avoid repeated API calls
const COVER_CACHE_KEY = 'novelist_cover_cache';

//REST KEY REQUIRED FOR ISBNDB API
const YOUR_REST_KEY = '59808_e93a74931913400b92d1b19ec2f103bb'


const PageContainer = styled.div`
  max-width: 1400px;
  margin: 2rem auto;
  padding: 0 1rem;
`;

const Header = styled.div`
  margin-bottom: 2rem;
  text-align: center;
`;

const Title = styled.h1`
  color: #1a5970;
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  color: #667c8a;
  font-size: 1.1rem;
`;

const ControlBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const BookCount = styled.span`
  font-weight: 600;
`;

const SortControls = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  
  label {
    font-weight: 600;
    color: #1a5970;
  }
  
  select {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    background-color: white;
    color: #495057;
    font-size: 0.9rem;
    
    &:focus {
      border-color: #1a5970;
      outline: none;
      box-shadow: 0 0 0 0.2rem rgba(26, 89, 112, 0.25);
    }
  }
`;

const BookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;

const BookCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }
`;

const BookCover = styled.div`
  height: 200px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  ${BookCard}:hover & img {
    transform: scale(1.05);
  }
`;

const BookInfo = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const BookTitle = styled.a`
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #1a5970;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const BookAuthor = styled.div`
  font-size: 0.9rem;
  color: #667c8a;
  margin-bottom: 0.75rem;
`;

const BookMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  color: #6c757d;
`;

const BookRating = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const DateAdded = styled.span`
  white-space: nowrap;
`;

const Pages = styled.div`
  color: #6c757d;
  font-size: 0.85rem;
  margin-bottom: 1rem;
`;

const ActionButton = styled.button`
  margin-top: auto;
  padding: 0.6rem;
  border: none;
  border-radius: 0.25rem;
  background-color: ${props => props.secondary ? '#e9ecef' : '#1a5970'};
  color: ${props => props.secondary ? '#495057' : 'white'};
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  
  &:hover {
    background-color: ${props => props.secondary ? '#dee2e6' : '#146280'};
  }
  
  &:disabled {
    background-color: ${props => props.secondary ? '#f8f9fa' : '#a3bcc5'};
    cursor: not-allowed;
  }
  
  .spinner {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  color: #1a5970;
  
  .spinner {
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: spin 1s linear infinite;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem 1rem;
  
  h3 {
    color: #1a5970;
    margin-bottom: 1rem;
  }
  
  p {
    color: #667c8a;
    max-width: 500px;
    margin: 0 auto;
  }
`;

// All your styled components remain the same...
// PageContainer, Header, Title, etc. 

const GoodReadsWantToRead = () => {
  const { user, bookList, setBookList } = useContext(LocalStateContext);
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState('dateAdded');
  const [sortOrder, setSortOrder] = useState('desc');
  const [loadingDetails, setLoadingDetails] = useState({});
  const [addingBook, setAddingBook] = useState({});

  // Load cover cache from localStorage
  const loadCoverCache = () => {
    try {
      const cached = localStorage.getItem(COVER_CACHE_KEY);
      return cached ? JSON.parse(cached) : {};
    } catch (err) {
      console.error('Error loading cover cache:', err);
      return {};
    }
  };

  // Save cover to cache
  const saveCoverToCache = (id, url) => {
    try {
      const cache = loadCoverCache();
      cache[id] = url;
      localStorage.setItem(COVER_CACHE_KEY, JSON.stringify(cache));
    } catch (err) {
      console.error('Error saving to cover cache:', err);
    }
  };

  // Helper function for title/author search
  const searchCoverByTitleAuthor = useCallback(async (title, author) => {
    if (!title) return null;
    
    try {
      // Note: Direct GoodReads scraping would need a backend proxy
      // This is intentionally returning null as noted in your comment
      return null;
    } catch (err) {
      console.error(`Error searching cover by title/author:`, err);
      return null;
    }
  }, []);

// Enhanced fetch book cover with multiple sources including ISBN DB
const fetchBookCover = useCallback(async (bookId, isbn) => {
  // Check cache first
  const cache = loadCoverCache();
  if (cache[bookId]) {
    setBooks(prevBooks => 
      prevBooks.map(book => 
        book.book_id === bookId ? { ...book, image: cache[bookId] } : book
      )
    );
    return;
  }
  
  // Clean up ISBN if available
  const cleanIsbn = isbn ? isbn.replace(/[="]/g, '').trim() : '';
  
  try {
    let coverUrl = null;
    
    // SOURCE 1: Try GoodReads cover by ID pattern (most reliable for GoodReads books)
    const grPatterns = [
      `https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/covers/${bookId}-L.jpg`,
      `https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${bookId}._SY475_.jpg`,
      `https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${bookId}.jpg`
    ];
    
    for (const pattern of grPatterns) {
      coverUrl = pattern;
      break;
    }
    
    // SOURCE 2: Try ISBN DB API (new addition)
    if (!coverUrl && cleanIsbn) {
      try {
        console.log(`Trying ISBN DB API for ISBN: ${cleanIsbn}`);
        const isbnDbResponse = await fetch(`https://api2.isbndb.com/book/${cleanIsbn}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": YOUR_REST_KEY
          }
        });
        
        if (isbnDbResponse.ok) {
          const isbnData = await isbnDbResponse.json();
          if (isbnData.book && isbnData.book.image) {
            coverUrl = isbnData.book.image;
            console.log(`ISBN DB API returned image URL: ${coverUrl}`);
          }
        }
      } catch (err) {
        console.log('ISBN DB API fetch failed, trying next source');
      }
    }
    
    // SOURCE 3: Try Google Books API without key (public access)
    if (!coverUrl && cleanIsbn) {
      try {
        // This endpoint doesn't require an API key for basic usage
        const googleResponse = await fetch(
          `https://www.googleapis.com/books/v1/volumes?q=isbn:${cleanIsbn}`
        );
        
        if (googleResponse.ok) {
          const googleData = await googleResponse.json();
          if (googleData.items?.[0]?.volumeInfo?.imageLinks) {
            coverUrl = googleData.items[0].volumeInfo.imageLinks.thumbnail;
            // Replace http with https and remove zoom parameters for higher quality
            coverUrl = coverUrl.replace('http:', 'https:').replace('&zoom=1', '');
            
            // Get higher res by modifying URL
            coverUrl = coverUrl.replace('zoom=1', 'zoom=0').replace('&edge=curl', '');
          }
        }
      } catch (err) {
        console.log('Google Books API fetch failed, trying next source');
      }
    }
    
    // SOURCE 4: Try a different OpenLibrary pattern
    if (!coverUrl && cleanIsbn) {
      // Try both medium and large sizes
      coverUrl = `https://covers.openlibrary.org/b/isbn/${cleanIsbn}-M.jpg`;
    }
    
    // If we found a cover or have a URL to try, update book and cache it
    if (coverUrl) {
      console.log(`Found potential cover URL for "${bookId}": ${coverUrl}`);
      
      setBooks(prevBooks => 
        prevBooks.map(b => 
          b.book_id === bookId ? { ...b, image: coverUrl } : b
        )
      );
      
      // Save to cache to avoid future API calls only if it's not from OpenLibrary
      // (since we can't verify OpenLibrary covers easily)
      if (!coverUrl.includes('openlibrary.org')) {
        saveCoverToCache(bookId, coverUrl);
      }
    } else {
      // Use placeholder if all sources failed
      setBooks(prevBooks => 
        prevBooks.map(b => 
          b.book_id === bookId ? { ...b, image: placeholderImage } : b
        )
      );
    }
  } catch (err) {
    console.error(`Error fetching cover for book ${bookId}:`, err);
    setBooks(prevBooks => 
      prevBooks.map(book => 
        book.book_id === bookId ? { ...book, image: placeholderImage } : book
      )
    );
  }
}, []);

// Update processCoversBatch to include ISBN DB API option
const processCoversBatch = useCallback((bookBatch) => {
  if (!bookBatch || bookBatch.length === 0) {
    setLoading(false);
    return;
  }

  // Update all books at once with several cover sources to try
  setBooks(prevBooks => {
    return prevBooks.map(book => {
      // If this book is in the cache, use cached cover
      const cache = loadCoverCache();
      if (cache[book.book_id]) {
        return { ...book, image: cache[book.book_id] };
      }
      
      // Create an array of cover sources to try (in order of preference)
      const coverSources = [];
      
      // 1. GoodReads cover (most reliable for GoodReads books)
      coverSources.push(`https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${book.book_id}._SY475_.jpg`);
      
      // 2. ISBN DB API (new addition)
      // We'll need to handle this separately since it requires an API call
      // Just storing the source type for now
      if (book.isbn) {
        const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
        book.isbnForApi = cleanIsbn; // Store for later API call
      }
      
      // 3. OpenLibrary cover if ISBN exists
      if (book.isbn) {
        const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
        coverSources.push(`https://covers.openlibrary.org/b/isbn/${cleanIsbn}-L.jpg`);
      }

      // 4. Google Books cover if ISBN exists
      if (book.isbn) {
        const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
        coverSources.push(`https://books.google.com/books/content?id=ISBN_${cleanIsbn}&printsec=frontcover&img=1&zoom=1&source=gbs_api`);
      }
      
      // Set the image attribute to multiple sources
      return { 
        ...book, 
        image: coverSources[0], // Start with first source
        coverSources: coverSources, // Store all sources to try in order
        needsIsbnDbCheck: !!book.isbn // Flag to indicate we should try the ISBN DB API
      };
    });
  });
  
  // After setting up the initial books, trigger ISBN DB API calls for books with ISBNs
  setTimeout(() => {
    getCoversFromIsbnDb();
  }, 100);
  
  // Set loading to false after all books have been processed
  setLoading(false);
}, []);

// Add a new function to handle ISBN DB API calls in batch
const getCoversFromIsbnDb = useCallback(async () => {
  // Get books that need ISBN DB check
  const booksToCheck = books.filter(book => book.needsIsbnDbCheck && book.isbnForApi);
  
  if (booksToCheck.length === 0) return;
  
  console.log(`Checking ISBN DB API for ${booksToCheck.length} books`);
  
  // Process in smaller batches to avoid overwhelming the API
  const batchSize = 5;
  
  for (let i = 0; i < booksToCheck.length; i += batchSize) {
    const batch = booksToCheck.slice(i, i + batchSize);
    
    // Process batch in parallel
    await Promise.all(batch.map(async (book) => {
      try {
        // Don't check if book already has image from cache
        if (book.image !== placeholderImage) return;
        
        const response = await fetch(`https://api2.isbndb.com/book/${book.isbnForApi}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": YOUR_REST_KEY
          }
        });
        
        if (response.ok) {
          const data = await response.json();
          
          if (data.book && data.book.image) {
            // Update book with new image
            setBooks(prevBooks => prevBooks.map(b => 
              b.book_id === book.book_id 
                ? { ...b, image: data.book.image, needsIsbnDbCheck: false } 
                : b
            ));
            
            // Cache the cover
            saveCoverToCache(book.book_id, data.book.image);
          }
        }
      } catch (error) {
        console.error(`Error fetching from ISBN DB for book ${book.book_id}:`, error);
      }
    }));
    
    // Add a small delay between batches to be nice to the API
    if (i + batchSize < booksToCheck.length) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}, [books]);
  // Load CSV data function
  const loadCsvData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Loading GoodReads CSV...');
      
      const response = await fetch('/wanttoread/goodreads_library_export.csv');
      if (!response.ok) {
        throw new Error(`Failed to load CSV file: ${response.status} ${response.statusText}`);
      }
      
      const csvText = await response.text();
      
      // Parse CSV with improved configuration
      Papa.parse(csvText, {
        header: true,
        delimiter: ",",
        skipEmptyLines: true,
        dynamicTyping: true,
        comments: "#",
        error: (error) => {
          console.error('CSV parsing error:', error);
          toast.error('Error parsing GoodReads data');
        },
        transform: (value) => {
          if (typeof value === 'string') {
            return value.replace(/^["=]+|["]+$/g, '');
          }
          return value;
        },
// Update this part of your loadCsvData function

complete: (results) => {
  console.log('CSV parsing complete');
  
  if (results.errors && results.errors.length > 0) {
    console.warn('CSV parsing had some non-fatal errors:', results.errors);
    toast.warn('Some books may not appear due to formatting issues in the export');
  }
  
  // Filter for valid books and "to-read" shelf items only
  const toReadBooks = results.data.filter(book => 
    book['Book Id'] && // Must have ID
    book['Exclusive Shelf']?.toLowerCase() === 'to-read'
  );
  
  console.log(`Found ${toReadBooks.length} books in want-to-read shelf`);
  
  // Transform data format with careful null handling
  const formattedBooks = toReadBooks.map(book => ({
    book_id: String(book['Book Id'] || ''),
    title: book['Title'] || 'Unknown Title',
    author: book['Author'] || 'Unknown Author',
    averageRating: parseFloat(book['Average Rating']) || 0,
    ratingsCount: parseInt(book['Num Ratings'] || '0'),
    dateAdded: book['Date Added'] ? new Date(book['Date Added']) : new Date(),
    goodreadsUrl: `https://www.goodreads.com/book/show/${book['Book Id']}`,
    pages: book['Number of Pages'] ? parseInt(book['Number of Pages']) : null,
    isbn: (book['ISBN13'] || book['ISBN'] || '').toString().replace(/[="]/g, ''),
    publicationYear: book['Year Published'] || book['Original Publication Year'] || '',
    image: placeholderImage // Start with placeholder
  }));
  
  // Process books and covers in a simplified way
  processCoversBatch(formattedBooks);
  
  // Set the books directly - the processCoversBatch function will update with covers
  setBooks(formattedBooks);
}
      });
    } catch (err) {
      console.error('Error loading GoodReads CSV:', err);
      setError(err.message || 'Failed to load books');
      toast.error('Unable to load your GoodReads list');
      setLoading(false);
    }
  }, [processCoversBatch]);

  // Add book to reading list
  const handleAddBook = useCallback(async (book) => {
    if (addingBook[book.book_id]) return;
    
    try {
      setAddingBook(prev => ({ ...prev, [book.book_id]: true }));
      
      // Prepare book data for addition
      const bookData = {
        title: book.title,
        author: book.author,
        image: book.image || placeholderImage,
        averageRating: book.averageRating,
        ratingsCount: book.ratingsCount || 0,
        pages: book.pages || 0,
        originURL: book.goodreadsUrl,
        releaseYear: book.publicationYear
      };
      
      // Add to reading list
      const result = await addBook({
        bookData,
        user,
        length: bookList.length
      });
      
      if (!result?.payload?.book) {
        throw new Error('Failed to add book to your reading list');
      }
      
      // Update local state
      setBookList([...bookList, { ...result.payload.book }]);
      
      // Remove from want-to-read display
      setBooks(prev => prev.filter(b => b.book_id !== book.book_id));
      
      toast.success(`Added "${book.title}" to your reading list`);
    } catch (err) {
      console.error(`Error adding book ${book.book_id}:`, err);
      toast.error(`Failed to add "${book.title}" to your reading list`);
    } finally {
      setAddingBook(prev => {
        const updated = { ...prev };
        delete updated[book.book_id];
        return updated;
      });
    }
  }, [addingBook, bookList, setBookList, user]);

  // Load initial data
  useEffect(() => {
    loadCsvData();
  }, [loadCsvData]);

  // Sort books based on current sort settings
  const sortedBooks = React.useMemo(() => {
    if (!books.length) return [];
    
    return [...books].sort((a, b) => {
      let comparison = 0;
      
      if (sortBy === 'title') {
        comparison = a.title.localeCompare(b.title);
      } else if (sortBy === 'author') {
        comparison = a.author.localeCompare(b.author);
      } else if (sortBy === 'rating') {
        comparison = (b.averageRating || 0) - (a.averageRating || 0);
      } else if (sortBy === 'dateAdded') {
        // Convert date strings to comparable values
        const dateA = new Date(a.dateAdded).getTime();
        const dateB = new Date(b.dateAdded).getTime();
        comparison = dateB - dateA; // Most recent first as default
      }
      
      return sortOrder === 'asc' ? -comparison : comparison;
    });
  }, [books, sortBy, sortOrder]);

  // Handle sort change
  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  // Handle order change
  const handleOrderChange = (e) => {
    setSortOrder(e.target.value);
  };

  // Render the component
  if (loading) {
    return (
      <PageContainer>
        <LoadingIndicator>
          <span className="spinner">⟳</span>
          <p>Loading your GoodReads want-to-read shelf...</p>
        </LoadingIndicator>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <EmptyState>
          <h3>Could not load your GoodReads list</h3>
          <p>{error}</p>
          <ActionButton secondary onClick={loadCsvData} style={{ width: 'auto', margin: '1rem auto' }}>
            Try Again
          </ActionButton>
        </EmptyState>
      </PageContainer>
    );
  }

  if (!books.length) {
    return (
      <PageContainer>
        <EmptyState>
          <h3>Your GoodReads want-to-read shelf is empty</h3>
          <p>Books you mark as "want to read" on GoodReads will appear here.</p>
        </EmptyState>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>
        <Title>Your GoodReads Want-to-Read List</Title>
        <Subtitle>Books you've marked as "want to read" on GoodReads</Subtitle>
      </Header>
      
      <ControlBar>
        <BookCount>{sortedBooks.length} books</BookCount>
        <SortControls>
          <label htmlFor="sort-by">Sort by:</label>
          <select id="sort-by" value={sortBy} onChange={handleSortChange}>
            <option value="dateAdded">Date Added</option>
            <option value="rating">Rating</option>
            <option value="title">Title</option>
            <option value="author">Author</option>
          </select>
          
          <select id="sort-order" value={sortOrder} onChange={handleOrderChange}>
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>
        </SortControls>
      </ControlBar>
      
      <BookGrid>
        {sortedBooks.map(book => (
          <BookCard key={book.book_id}>


<BookCover>


  <BookCoverImage book={book} placeholderImage={placeholderImage} />

</BookCover>
            <BookInfo>
              <BookTitle 
                href={book.goodreadsUrl} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {book.title}
              </BookTitle>
              <BookAuthor>by {book.author}</BookAuthor>
              <BookMeta>
                <BookRating>
                  <Stars stars={book.averageRating || 0} size="small" />
                  <span>{book.averageRating?.toFixed(1) || '0.0'}</span>
                </BookRating>
                <DateAdded>
                  Added {new Date(book.dateAdded).toLocaleDateString()}
                </DateAdded>
              </BookMeta>
              
              {book.pages && <Pages>{book.pages} pages</Pages>}
              
              <ActionButton 
                onClick={() => handleAddBook(book)}
                disabled={addingBook[book.book_id]}
              >
                {addingBook[book.book_id] ? (
                  <>
                    <span className="spinner">⟳</span> Adding to List
                  </>
                ) : (
                  'Add to Reading List'
                )}
              </ActionButton>
            </BookInfo>
          </BookCard>
        ))}
      </BookGrid>
    </PageContainer>
  );
};

export default GoodReadsWantToRead;