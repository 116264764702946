import React, { useContext, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { LocalStateContext } from "../State/LocalState";
import { styled } from "styled-components";
import Stars from "./Stars";
import { motion } from "framer-motion"; // If you have framer-motion installed

// Header with year and navigation
const YearHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #1a5970;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  
  &:hover {
    text-decoration: underline;
  }
  
  svg {
    margin-right: 0.5rem;
  }
`;

const YearTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a5970;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Responsive container
const MainContainer = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  
  @media (max-width: 1024px) {
    padding: 1.5rem;
  }
  
  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 2rem;
  
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 300px;
  }
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

// Book grid with responsive columns
const BookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 1rem;
  }
`;

// Enhanced book card
const BookCard = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`;

const BookCover = styled.div`
  height: 250px;
  width: 100%;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.5s ease;
  }
  
  ${BookCard}:hover & img {
    transform: scale(1.05);
  }
  
  @media (max-width: 768px) {
    height: 200px;
  }
`;

const BookInfo = styled.div`
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const BookTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  margin: 0 0 0.5rem;
  line-height: 1.3;
  color: #333;
`;

const BookAuthor = styled.p`
  font-size: 0.875rem;
  color: #667c8a;
  margin: 0 0 0.75rem;
`;

const BookMeta = styled.div`
  margin-top: auto;
  font-size: 0.875rem;
  color: #667c8a;
  
  .rating {
    margin-bottom: 0.5rem;
  }
  
  .pages {
    display: flex;
    justify-content: space-between;
  }
`;

// Stats panel
const StatsPanel = styled.aside`
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border-top: 6px solid #1a5970;
  height: fit-content;
  position: sticky;
  top: 2rem;
  
  @media (max-width: 768px) {
    position: static;
    margin-top: 1.5rem;
  }
`;

const StatTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 1.5rem;
  color: #1a5970;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.75rem;
`;

const StatItem = styled.div`
  margin-bottom: 1.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StatLabel = styled.div`
  font-size: 0.875rem;
  color: #667c8a;
  margin-bottom: 0.25rem;
`;

const StatValue = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
  color: #333;
`;

// Empty state
const EmptyState = styled.div`
  text-align: center;
  padding: 4rem 1rem;
  color: #667c8a;
  grid-column: 1 / -1;
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1a5970;
  }
  
  p {
    font-size: 1.1rem;
    max-width: 500px;
    margin: 0 auto 1.5rem;
  }
`;

const ReadingHistoryYear = () => {
  const { finishedBooks } = useContext(LocalStateContext);
  let { year } = useParams();
  
  // Safely access finished books and provide defaults
  const books = useMemo(() => {
    if (!finishedBooks || !finishedBooks[year]) return [];
    return finishedBooks[year];
  }, [finishedBooks, year]);
  
// Update the stats calculation:

const stats = useMemo(() => {
  if (!books || books.length === 0) {
    return { 
      totalBooks: 0,
      totalPages: 0,
      avgRating: 0,
      avgPages: 0,
      topRated: null,
      longestBook: null
    };
  }
  
  const totalBooks = books.length;
  // Access book data through the nested structure
  const totalPages = books.reduce((sum, bookEntry) => {
    const book = bookEntry.book || {};
    return sum + (book.pages || 0);
  }, 0);
  
  const avgPages = Math.round(totalPages / totalBooks);
  
  // Handle ratings safely with the correct nesting
  const booksWithRatings = books.filter(bookEntry => 
    bookEntry.book && bookEntry.book.averageRating
  );
  
  const avgRating = booksWithRatings.length > 0
    ? (booksWithRatings.reduce((sum, bookEntry) => 
        sum + bookEntry.book.averageRating, 0) / booksWithRatings.length).toFixed(1)
    : "N/A";
  
  // Find top rated and longest books safely with correct nesting
  const topRated = [...books]
    .filter(bookEntry => bookEntry.book && bookEntry.book.averageRating)
    .sort((a, b) => b.book.averageRating - a.book.averageRating)[0]?.book || null;
    
  const longestBook = [...books]
    .filter(bookEntry => bookEntry.book && bookEntry.book.pages)
    .sort((a, b) => b.book.pages - a.book.pages)[0]?.book || null;
  
  return {
    totalBooks,
    totalPages,
    avgRating,
    avgPages,
    topRated,
    longestBook
  };
}, [books]);

  return (
    <MainContainer>
      <YearHeader>
        <BackLink to="/history">
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 19L5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Back to History
        </BackLink>
        <YearTitle>{year} Reading History</YearTitle>
      </YearHeader>

      {books.length === 0 ? (
        <EmptyState>
          <h3>No books found for {year}</h3>
          <p>We couldn't find any books you read during this year.</p>
          <BackLink to="/history">Return to Reading History</BackLink>
        </EmptyState>
      ) : (
        <ContentWrapper>
          <BookGrid>


{books.map((bookEntry, index) => {
  // Access the actual book object from the nested structure
  const book = bookEntry.book || {};
  
  return (
    <BookCard 
      key={book._id || index}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.05 }}
    >
      <BookCover>
        <img 
          src={book.image} 
          alt={`Cover of ${book.title}`} 
          onError={(e) => {
            e.target.src = '/placeholder-book-cover.jpg'; // Fallback image
          }}
        />
      </BookCover>
      <BookInfo>
        <BookTitle>{book.title || "Unknown Title"}</BookTitle>
        <BookAuthor>by {book.author || "Unknown Author"}</BookAuthor>
        <BookMeta>
          {book.averageRating && (
            <div className="rating">
              <Stars stars={book.averageRating} />
            </div>
          )}
          <div className="pages">
            <span>{book.pages || "?"} pages</span>
            {bookEntry.dateFinished && (
              <span>
                {new Date(bookEntry.dateFinished).toLocaleDateString(undefined, { 
                  month: 'short', 
                  day: 'numeric' 
                })}
              </span>
            )}
          </div>
        </BookMeta>
      </BookInfo>
    </BookCard>
  );
})}
          </BookGrid>

          <StatsPanel>
            <StatTitle>{year} Reading Stats</StatTitle>
            <StatItem>
              <StatLabel>Books Read</StatLabel>
              <StatValue>{stats.totalBooks}</StatValue>
            </StatItem>
            <StatItem>
              <StatLabel>Pages Read</StatLabel>
              <StatValue>{stats.totalPages.toLocaleString()}</StatValue>
            </StatItem>
            <StatItem>
              <StatLabel>Average Pages per Book</StatLabel>
              <StatValue>{stats.avgPages}</StatValue>
            </StatItem>
            <StatItem>
              <StatLabel>Average Rating</StatLabel>
              <StatValue>{stats.avgRating}</StatValue>
            </StatItem>
            
            {stats.topRated && (
              <StatItem>
                <StatLabel>Highest Rated Book</StatLabel>
                <StatValue>{stats.topRated.title} ({stats.topRated.averageRating}★)</StatValue>
              </StatItem>
            )}
            
            {stats.longestBook && (
              <StatItem>
                <StatLabel>Longest Book</StatLabel>
                <StatValue>{stats.longestBook.title} ({stats.longestBook.pages} pages)</StatValue>
              </StatItem>
            )}
          </StatsPanel>
        </ContentWrapper>
      )}
    </MainContainer>
  );
};

export default ReadingHistoryYear;