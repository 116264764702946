import React, { useContext, useCallback, useMemo, useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { BookCard } from "./BookCard";
import { LocalStateContext } from "../State/LocalState";
import styled from "styled-components";

const ListContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 0 10px;
  
  @media (max-width: 768px) {
    padding: 0 5px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  font-size: 52px;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  margin-top: 35px;

`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(30px, 0.25fr) 3fr;
  grid-template-rows: 1fr;
  gap: 10px;
  width: 100%;
  
  @media (max-width: 768px) {
    grid-template-columns: minmax(20px, 0.15fr) 3fr;
  }
`;

const BookStackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  padding: 100px 0;
`;

const BookStack = styled.div`
  position: relative;
  width: 125px;
  height: 175px;
`;

const Book = styled.div`
  position: absolute;
  left: ${props => props.offset || '0px'};
  bottom: ${props => props.bottom || '0px'};
  width: 200px;
  height: 40px;
  background-color: ${props => props.color || '#1a5970'};
  border-radius: 3px;
  transform: ${props => props.transform || 'none'};
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  animation: ${props => props.anim || 'none'} 1.5s infinite;
  
  @keyframes rise {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }
  
  @keyframes rise2 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-8px); }
  }
  
  @keyframes rise3 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-6px); }
  }
`;

const LoadingMessage = styled.p`
  margin-top: 20px;
  font-size: 42px;
  color: #1a5970;
  font-weight: 600;
`;

// BUGFIX #0: Refactor DragList component to use local state
// This change isolates the drag-and-drop logic from the context state,
// preventing render conflicts during drag operations
// The component now uses a local state to manage the displayed books,
// synchronizing with context only when the source data changes
// This ensures the UI remains consistent during drag operations
// The drag-and-drop logic remains unchanged, with minor improvements
// to ensure proper state management and stability during drag operations
// Styled components remain unchanged...

export const DragList = () => {
  const {
    loading,
    bookList,
    updateBookList,
    dragable,
    sorting,
    shadowList,
  } = useContext(LocalStateContext);

  // BUGFIX #1: Create local state to manage the displayed books
  // This isolates the draggable list from direct context updates,
  // preventing render conflicts during drag operations
  const [displayedBooks, setDisplayedBooks] = useState([]);
  
  // BUGFIX #2: Synchronize local state with context only when source data changes
  // This prevents the flickering/resetting issue during drag by ensuring
  // our local state only updates when the source data intentionally changes,
  // not during intermediate drag operations
  useEffect(() => {
    if (sorting === "ORDER_ASC") {
      setDisplayedBooks(bookList);
    } else {
      setDisplayedBooks(shadowList);
    }
  }, [sorting, bookList, shadowList]);

  // Title logic remains unchanged...
  const title = useMemo(() => {
    switch (sorting) {
      case "ORDER_ASC":
        return "My noveList Reading Order";
      case "averageRating_DESC":
        return "Library: Best Rated";
      case "averageRating_ASC":
        return "Library: Worst Rated";
      case "title_ASC":
        return "Library: A - Z";
      case "title_DESC":
        return "Library: Z - A";
      case "pages_DESC":
        return "Library: Longest Books";
      case "pages_ASC":
        return "Library: Shortest Books";
      default:
        return "No Title";
    }
  }, [sorting]);

  // Style getters remain unchanged...
  const grid = 8;

  const getItemStyle = useCallback((isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "#06455c75" : "#FFF",
    ...draggableStyle,
  }), []);

  const getListStyle = useCallback((isDraggingOver) => ({
    background: isDraggingOver ? "#FFF" : "#FFF",
    padding: grid,
    width: '100%',
    maxWidth: '800px',
    margin: "auto",
  }), []);

  // BUGFIX #3: Improved onDragEnd handler with proper state management
  const onDragEnd = useCallback((result) => {
    // Early returns for invalid drag operations - unchanged
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    if (sorting !== "ORDER_ASC") {
      return;
    }

    // BUGFIX #3.1: Use local state for reordering
    // This ensures we're working with a consistent reference to the list
    // rather than potentially stale context data
    const reordered = Array.from(displayedBooks);
    
    const [removed] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, removed);

    // BUGFIX #3.2: Update order properties explicitly
    // This ensures each item has a consistent order value that matches
    // its position in the list, which is critical for future drag operations
    const mutatedList = reordered.map((element, index) => ({
      ...element,
      order: index
    }));
    
    // BUGFIX #3.3: Update both states atomically
    // This ensures consistency between local state and context state,
    // preventing the UI from getting out of sync with the data model
    setDisplayedBooks(mutatedList);
    updateBookList(mutatedList);
    
    // Previous bug: Only updated context without updating local state,
    // causing the local state to be out of sync with context after first drag
  }, [displayedBooks, sorting, updateBookList]);

  // BUGFIX #4: Generate stable, consistent keys for draggable items
  // React-beautiful-dnd requires that draggableId remains stable across renders
  // Using index as key (common mistake) causes items to lose identity during reordering
  const getItemKey = useCallback((item) => {
    // Create a stable ID hierarchy using available unique identifiers
    // This prevents react-beautiful-dnd from getting confused about item identity
    return item._id || 
           (item.book && item.book._id) || 
           `book-${item.order}-${item.book?.title}`;
  }, []);

  return (
    <ListContainer>
      {/* Loading and empty states unchanged */}
      {loading && (
        <BookStackContainer>
          <BookStack>{/* Animation elements unchanged */}</BookStack>
          <LoadingMessage>Organizing your shelf...</LoadingMessage>
        </BookStackContainer>
      )}

      {!loading && !(displayedBooks.length > 0) && (
        <Title>Add Books to noveList</Title>
      )}

      {/* BUGFIX #5: Rendering the list with proper keys and state */}
      {displayedBooks.length > 0 && (
        <>
          <Title>{title}</Title>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {/* BUGFIX #5.1: Map over displayedBooks (local state) 
                      instead of directly using context state */}
                  {displayedBooks.map((item, index) => {
                    // BUGFIX #5.2: Generate stable key for each item
                    const itemKey = getItemKey(item);
                    return (
                      <Draggable
                        // BUGFIX #5.3: Use the same stable ID for both key and draggableId
                        // This consistency is critical for drag operations to work properly
                        key={itemKey}
                        draggableId={itemKey}
                        isDragDisabled={!dragable}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <CardGrid>
                              <h3>{dragable ? index + 1 + "." : ""}</h3>
                              {/* Pass the same index to BookCard for consistency */}
                              <BookCard book={item} index={index} />
                            </CardGrid>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </ListContainer>
  );
};