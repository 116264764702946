import React, { useState, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import IllustrationReading from "../Illustrations/Reading";
import { LocalStateContext } from "../State/LocalState";
import { FiMail, FiLock, FiLogIn, FiArrowRight } from 'react-icons/fi';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(24, 103, 192, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(24, 103, 192, 0); }
  100% { box-shadow: 0 0 0 0 rgba(24, 103, 192, 0); }
`;

// Container for the entire page
const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e8f0 100%);
  padding: 1.5rem;
`;

// Main card container
const LoginCard = styled.div`
  display: flex;
  max-width: 1000px;
  width: 100%;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  animation: ${fadeIn} 0.6s ease-out;
  
  @media (max-width: 768px) {
    flex-direction: column;
    max-width: 450px;
  }
`;

// Left side with illustration
const BrandSection = styled.div`
  flex: 1;
  background: linear-gradient(135deg, #1867c0 0%, #5e35b1 100%);
  color: white;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 768px) {
    padding: 2rem;
    align-items: center;
    text-align: center;
  }
`;

const IllustrationContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  svg {
    width: 90%;
    height: auto;
    max-width: 300px;
    filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1));
    path, circle {
      fill: rgba(255, 255, 255, 0.9);
    }
  }
`;

const BrandTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const BrandTagline = styled.p`
  font-size: 1.1rem;
  opacity: 0.9;
  line-height: 1.6;
`;

// Right side with form
const FormSection = styled.div`
  flex: 1;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const FormTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 0.5rem;
`;

const FormSubtitle = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
`;

const InputIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem 1rem 1rem 3rem;
  border: 1px solid ${props => props.error ? '#dc3545' : '#e1e1e1'};
  border-radius: 8px;
  font-size: 1rem;
  background-color: #f9f9f9;
  transition: all 0.2s;
  
  &:focus {
    outline: none;
    border-color: #1867c0;
    box-shadow: 0 0 0 3px rgba(24, 103, 192, 0.2);
    background-color: #fff;
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 0.5rem;
  }
`;

const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RememberMeRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

const Checkbox = styled.input`
  margin-right: 0.5rem;
  cursor: pointer;
`;

const ForgotPassword = styled(Link)`
  color: #1867c0;
  font-size: 0.875rem;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const LoginButton = styled.button`
  background: linear-gradient(135deg, #1867c0 0%, #2196f3 100%);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
  }
  
  &:active:not(:disabled) {
    transform: translateY(0);
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  svg {
    margin-left: 0.5rem;
  }
  
  &:focus:not(:disabled) {
    animation: ${pulse} 1.5s infinite;
  }
`;

const SignUpText = styled.p`
  text-align: center;
  margin-top: 2rem;
  color: #666;
  
  a {
    color: #1867c0;
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Login = () => {
  let location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const { user, setUser } = useContext(LocalStateContext);

  // Check if email was saved in localStorage
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  const login = async ({ email, password }) => {
    if (!email || !password) {
      setError("Please enter both email and password");
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${BACKEND_URL}/auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          payload: {
            email,
            password,
          },
        }),
      });
      
      const data = await response.json();
      
      if (data.user) {
        // Save email for "remember me" feature
        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
        } else {
          localStorage.removeItem("rememberedEmail");
        }
        
        // Store user data
        localStorage.setItem("user", JSON.stringify(data.user));
        setUser(data.user);
        setRedirect(true);
      } else {
        setError(data.message || "Invalid email or password");
      }
    } catch (err) {
      setError("Login failed. Please try again later.");
      console.error("Login error:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      {redirect && <Navigate to="/main" state={{ from: location }} replace />}
      
      <Routes>
        <Route
          path="/auth"
          element={
            user && <Navigate to="/main" state={{ from: location }} replace />
          }
        />
      </Routes>

      <LoginCard>
        <BrandSection>
          <BrandTitle>TheNovelist</BrandTitle>
          <BrandTagline>Your digital bookshelf for keeping track of your reading journey.</BrandTagline>
          
          <IllustrationContainer>
            <IllustrationReading />
          </IllustrationContainer>
        </BrandSection>
        
        <FormSection>
          <FormTitle>Welcome Back</FormTitle>
          <FormSubtitle>Please sign in to continue</FormSubtitle>
          
          {error && (
            <ErrorMessage>
              <FiLock size={16} /> {error}
            </ErrorMessage>
          )}
          
          <FormGroup>
            <InputIcon>
              <FiMail size={18} />
            </InputIcon>
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={isLoading}
              error={error && !email}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  login({ email, password });
                }
              }}
            />
          </FormGroup>
          
          <FormGroup>
            <InputIcon>
              <FiLock size={18} />
            </InputIcon>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              error={error && !password}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  login({ email, password });
                }
              }}
            />
          </FormGroup>

          <RememberMeRow>
            <CheckboxContainer>
              <Checkbox 
                type="checkbox" 
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              Remember me
            </CheckboxContainer>
            <ForgotPassword to="/forgot-password">Forgot password?</ForgotPassword>
          </RememberMeRow>
          
          <FormActions>
            <LoginButton 
              onClick={() => login({ email, password })}
              disabled={isLoading}
            >
              {isLoading ? "Signing in..." : "Sign In"} 
              {!isLoading && <FiArrowRight size={18} />}
            </LoginButton>
          </FormActions>
          
          <SignUpText>
            Don't have an account? <Link to="/register">Sign up</Link>
          </SignUpText>
        </FormSection>
      </LoginCard>
    </PageContainer>
  );
};