const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const addBook = async ({ bookData, user, length }) => {
  if (!bookData || !user) {
    throw new Error("Missing required book data or user information");
  }
  
  try {
    const response = await fetch(`${BACKEND_URL}/addbooktolist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: {
          bookData,
          user,
          length,
        },
      }),
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to add book to list");
    }
    
    const data = await response.json();
    
    if (!data.payload || !data.payload.book) {
      throw new Error("Invalid response from server");
    }
    
    return data;
  } catch (error) {
    console.error("Error adding book:", error);
    throw error;
  }
};