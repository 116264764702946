import React, { useState, useEffect } from 'react';

// Create a component specifically for book covers with multiple fallback sources
const BookCoverImage = ({ book, placeholderImage }) => {
  const [currentSrc, setCurrentSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  
  // Your ISBN DB API key - consider storing this in an environment variable
  const ISBN_DB_API_KEY = 'YOUR_REST_KEY'; // Replace with your actual key
  
  // Fetch covers from APIs and direct URLs
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    
    const fetchCover = async () => {
      try {
        // Try in order of preference:
        
        // 1. ISBN-based API (highest quality option)
        if (book.isbn) {
          const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
          try {
            console.log(`Trying ISBN API for "${book.title}" with ISBN: ${cleanIsbn}`);
            const response = await fetch(`https://bookcover.longitood.com/bookcover/${cleanIsbn}`);
            if (response.ok) {
              const data = await response.json();
              if (data.url && isMounted) {
                console.log(`Found cover for "${book.title}" via ISBN API`);
                setCurrentSrc(data.url);
                setIsLoading(false);
                return;
              }
            }
          } catch (err) {
            console.log(`ISBN API failed for "${book.title}": ${err.message}`);
          }
        }
        
        // 2. Title/Author API
        try {
          const encodedTitle = encodeURIComponent(book.title);
          const encodedAuthor = encodeURIComponent(book.author);
          console.log(`Trying Title/Author API for "${book.title}"`);
          const response = await fetch(
            `https://bookcover.longitood.com/bookcover?book_title=${encodedTitle}&author_name=${encodedAuthor}`
          );
          if (response.ok) {
            const data = await response.json();
            if (data.url && isMounted) {
              console.log(`Found cover for "${book.title}" via Title/Author API`);
              setCurrentSrc(data.url);
              setIsLoading(false);
              return;
            }
          }
        } catch (err) {
          console.log(`Title/Author API failed for "${book.title}": ${err.message}`);
        }
        
        // 3. NEW: Try ISBN DB API
        if (book.isbn) {
          const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
          try {
            console.log(`Trying ISBN DB API for "${book.title}" with ISBN: ${cleanIsbn}`);
            const isbnDbResponse = await fetch(`https://api2.isbndb.com/book/${cleanIsbn}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": ISBN_DB_API_KEY
              }
            });
            
            if (isbnDbResponse.ok) {
              const isbnData = await isbnDbResponse.json();
              if (isbnData.book && isbnData.book.image && isMounted) {
                console.log(`Found cover for "${book.title}" via ISBN DB API: ${isbnData.book.image}`);
                setCurrentSrc(isbnData.book.image);
                setIsLoading(false);
                return;
              }
            }
          } catch (err) {
            console.log(`ISBN DB API failed for "${book.title}": ${err.message}`);
          }
        }
        
        // 4. Try OpenLibrary cover API
        if (book.isbn) {
          const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
          if (isMounted) {
            console.log(`Trying OpenLibrary for "${book.title}"`);
            setCurrentSrc(`https://covers.openlibrary.org/b/isbn/${cleanIsbn}-L.jpg`);
            setIsLoading(false);
            return;
          }
        }
        
        // 5. Try GoodReads cover pattern
        if (isMounted) {
          console.log(`Trying GoodReads pattern for "${book.title}"`);
          setCurrentSrc(`https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${book.book_id}._SY475_.jpg`);
          setIsLoading(false);
          return;
        }
        
      } catch (err) {
        console.error(`Error fetching cover for "${book.title}":`, err);
        if (isMounted) {
          setCurrentSrc(placeholderImage);
          setIsLoading(false);
          setHasError(true);
        }
      }
    };
    
    fetchCover();
    
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [book.title, book.author, book.isbn, book.book_id, placeholderImage, ISBN_DB_API_KEY]);
  
  // Handle direct image loading errors
  const handleImageError = () => {
    console.log(`Image failed to load for "${book.title}": ${currentSrc}`);
    
    // Determine next source based on current source
    if (currentSrc && currentSrc.includes('openlibrary.org')) {
      // If OpenLibrary failed, try GoodReads
      console.log(`Trying GoodReads pattern after OpenLibrary failed for "${book.title}"`);
      setCurrentSrc(`https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${book.book_id}._SY475_.jpg`);
    } 
    else if (currentSrc && currentSrc.includes('gr-assets.com')) {
      // If GoodReads failed, try Google Books
      if (book.isbn) {
        const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
        console.log(`Trying Google Books after GoodReads failed for "${book.title}"`);
        setCurrentSrc(`https://books.google.com/books/content?id=ISBN_${cleanIsbn}&printsec=frontcover&img=1&zoom=1&source=gbs_api`);
      } else {
        // No more sources, use placeholder
        console.log(`All sources failed for "${book.title}", using placeholder`);
        setCurrentSrc(placeholderImage);
        setHasError(true);
      }
    }
    else if (currentSrc && currentSrc.includes('books.google.com')) {
      // If Google Books failed, use placeholder
      console.log(`All sources failed for "${book.title}", using placeholder`);
      setCurrentSrc(placeholderImage);
      setHasError(true);
    }
    else if (currentSrc && currentSrc.includes('isbndb.com')) {
      // If ISBN DB API failed, try OpenLibrary
      if (book.isbn) {
        const cleanIsbn = book.isbn.replace(/[="]/g, '').trim();
        console.log(`Trying OpenLibrary after ISBN DB API failed for "${book.title}"`);
        setCurrentSrc(`https://covers.openlibrary.org/b/isbn/${cleanIsbn}-L.jpg`);
      } else {
        // Skip to GoodReads if no ISBN
        console.log(`Trying GoodReads after ISBN DB API failed for "${book.title}"`);
        setCurrentSrc(`https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/${book.book_id}._SY475_.jpg`);
      }
    }
    else {
      // Unknown source or all failed, use placeholder
      console.log(`All sources failed for "${book.title}", using placeholder`);
      setCurrentSrc(placeholderImage);
      setHasError(true);
    }
  };
  
  // Show loading spinner while fetching covers
  if (isLoading) {
    return (
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#f8f9fa'
      }}>
        <div className="spinner" style={{ fontSize: '1.5rem' }}>⟳</div>
      </div>
    );
  }
  
  return (
    <img
      src={currentSrc || placeholderImage}
      alt={`Cover of ${book.title}`}
      onError={!hasError ? handleImageError : undefined}
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'opacity 0.3s ease',
        opacity: hasError && currentSrc === placeholderImage ? 0.7 : 1
      }}
    />
  );
};

export default BookCoverImage;